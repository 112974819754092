import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndCondition from "./TermsAndCondition";
import About from "./About";
import FAQ from "./FAQ";

import "./cms.css";

const Cms = () => {
  const { pathname } = useLocation();

  const content = () => {
    switch (pathname) {
      case "/about-us":
        return <About />;
      case "/terms-and-condition":
        return <TermsAndCondition />;
      case "/privacy-policy":
        return <PrivacyPolicy />;
      case "/faq":
        return <FAQ />;
      default:
        return "";
    }
  };
  
  const isLogin = localStorage.getItem("token")
  return (
    <div className={isLogin ? `cms-container` : 'guest-container'}>
      <div className="cms_body">{content()}</div>
    </div>
  );
};

export default Cms;
