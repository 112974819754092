import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";

import { ethers } from "ethers";
import { signMessage } from "./utils/signmessage";
import { didUserReject } from "./utils";
import { useSwitchChain } from "./hooks/useSwitchChain";
import usePrevious from "./hooks/usePrevious";
import { currentNetwork } from "./constant/dataConstant";
import {
  fetchIcoInfo,
  fetchProfileInfo,
  resetMessage,
  resetMessageIco,
  setDollarRate,
  signWalletSuccess,
  walletLoginSuccess,
} from "./store/actions";

import { userRoutes, authRoutes } from "./routes/allRoutes";
import { AlertMessageModal } from "./components/UI";
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import "./assets/scss/theme.scss";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import axios from "axios";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import accountChange from "./utils/accountChange";

const toastSettings = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const App = () => {
  const { account, provider, isActive, chainId, connector } = useWeb3React();
  const { msg, msgStatus, isAuthenticated, userData } = useSelector(
    (state) => state.auth
  );
  const { msg: icoMessage, msgStatus: icoStatus } = useSelector(
    (state) => state.ico
  );
  const { open } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  const walletAddress = localStorage.getItem("walletAddress");

  const fetchUSDprice = async () => {
    try {
      let resp = await axios({
        method: "get",
        url: `https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd`,
      });
      if (resp.status === 200) {
        var price = resp.data.ethereum.usd;
        dispatch(setDollarRate(price));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchIcoInfo());
    }
  }, [isAuthenticated]);

  // const prevAccount = usePrevious(account);

  const checkAccount = async () => {
    const lowercaseWalletAddress = walletAddress.toLowerCase();
    const lowercaseAccount = account.toLowerCase();

    if (lowercaseWalletAddress !== lowercaseAccount) {
      accountChange(connector, provider, account, dispatch);
    }
  };

  // accounts changed
  useEffect(() => {
    if (account && walletAddress) checkAccount();
  }, [account]);

  const switchChain = useSwitchChain();
  const switchNetwork = async () => {
    if (currentNetwork !== chainId) {
      try {
        await switchChain(connector, currentNetwork, provider);
      } catch (error) {
        if (didUserReject(error)) {
          // Ignore error, which keeps the user on the previous chain.
        } else {
          throw error;
        }
      }
    }
  };

  // wrong chain id detected
  useEffect(() => {
    if (account && chainId) switchNetwork();
  }, [account, chainId, connector]);

  useEffect(() => {
    if (msgStatus === "success") {
      toast.success(msg, toastSettings);
    } else if (msgStatus === "fail") {
      toast.error(msg, toastSettings);
    }
    if (msg) {
      dispatch(resetMessage());
    }
  }, [msg, dispatch, msgStatus]);

  useEffect(() => {
    if (icoStatus === "success") {
      toast.success(icoMessage, toastSettings);
    } else if (icoStatus === "fail") {
      toast.error(icoMessage, toastSettings);
    }
    if (icoMessage) {
      dispatch(resetMessageIco());
    }
  }, [icoMessage, dispatch, icoStatus]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProfileInfo());
      fetchUSDprice();
    }
  }, [isAuthenticated]);

  let routes = [];
  switch (isAuthenticated) {
    case true:
      routes = userRoutes;
      break;
    default:
      routes = authRoutes;
  }

  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <Routes>
          {routes.map((route, idx) =>
            route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  isAuthenticated ? (
                    <VerticalLayout>
                      <route.component />
                    </VerticalLayout>
                  ) : (
                    <NonAuthLayout>
                      <route.component />
                    </NonAuthLayout>
                  )
                }
              />
            ) : (
              route.redirectRoute && (
                <Route
                  path="*"
                  key={idx}
                  element={<Navigate to={route.path} />}
                />
              )
            )
          )}
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
        />
        {open && <AlertMessageModal />}
      </Router>
    </React.Fragment>
  );
};

export default App;
