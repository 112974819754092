import React from "react";
import { Navigate } from "react-router-dom";

//User components
import WalletLogin from "../views/Login/Login";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import EmailVerificationSuccess from "../pages/Utility/email-verification-success";
import EmailVerificationFailed from "../pages/Utility/email-verfication-failed";

//Auth components
import Signup from "../pages/Signup/Signup";
import DashboardChart from "../pages/DashboardChart";
import AllTransaction from "../pages/Transactions";
import BuyCoin from "../pages/BuyCoin/index";
import ContactUs from "../pages/Contact/ContactUs";
import Profile from "../pages/Profile/Profile";
import Referral from "../pages/Referral/Referral";
import PrivateSaleRecord from "../pages/Transactions/PrivateSaleRecord";
import Cms from "../pages/CMS/Cms";

export const userRoutes = [
  {
    path: "/dashboard",
    component: DashboardChart,
  },
  { path: "/transaction", component: AllTransaction },
  { path: "/buycoin", component: BuyCoin },
  { path: "/help-center", component: ContactUs },
  { path: "/profile", component: Profile },
  { path: "/referral", component: Referral },
  { path: "/claim-history/:vestingId", component: PrivateSaleRecord },
  { path: "/about-us", component: Cms },
  { path: "/terms-and-condition", component: Cms },
  { path: "/privacy-policy", component: Cms },
  { path: "/faq", component: Cms },

  // this route should be at the end of all other routes
  {
    redirectRoute: true,
    name: "Dashboard",
    path: "/dashboard",
  },
];

export const authRoutes = [
  { path: "/login", component: WalletLogin },
  { path: "/signup", component: Signup },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/verification-success", component: EmailVerificationSuccess },
  { path: "/verification-failed", component: EmailVerificationFailed },
  { path: "/faq", component: Cms },
  {
    redirectRoute: true,
    name: "Login",
    path: "/login",
  },
];
