import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Countdown
// import Countdown from "react-countdown";

//Import Images
import logo from "../../assets/images/logo/home.png";
import commingsoon from "../../assets/images/coming-soon-img.png";
import { Link } from "react-router-dom";

const PagesComingsoon = () => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  // const renderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     // Render a completed state
  //     return <span></span>;
  //   } else {
  //     return (
  //       <>
  //         <div className="coming-box">
  //           {days} <span>Days</span>
  //         </div>{" "}
  //         <div className="coming-box">
  //           {hours} <span>Hours</span>
  //         </div>{" "}
  //         <div className="coming-box">
  //           {minutes} <span>Minutes</span>
  //         </div>{" "}
  //         <div className="coming-box">
  //           {seconds} <span>Seconds</span>
  //         </div>
  //       </>
  //     );
  //   }
  // };

  return (
    <React.Fragment>
      <div className="my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="40"
                    className="logo logo-dark"
                  />
                  {/* <img
                    src={logolight}
                    alt=""
                    height="22"
                    className="logo logo-light"
                  /> */}
                </Link>

                <Row className="justify-content-center mt-5">
                  <Col lg={4} sm={5}>
                    <div className="maintenance-img">
                      <img
                        src={commingsoon}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>

                <h5 className="mt-5">Page coming soon</h5>

                <Row className="justify-content-center mt-5">
                  <Col lg={10}>
                    <div className="counter-number">
                      {/* <Countdown date="2021/12/31" renderer={renderer} /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesComingsoon;
