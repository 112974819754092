import { formatFixed } from "@ethersproject/bignumber";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { getPersistedConnectionMeta } from "./meta";
import { clearConnectorStorage } from "./web3/web3";
import { resetApp } from "../store/actions";

export { default as errorHandler } from "./errorHandler/errorHandler";

export const delay = (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getStartTime = (nftReleaseDate) => {
  const currentTime = Math.round(Date.now() / 1000) + 120;
  const releaseTime = +new Date(nftReleaseDate) / 1000;
  const releaseDate = nftReleaseDate
    ? currentTime < releaseTime
      ? releaseTime
      : currentTime
    : currentTime;
  return releaseDate;
};

export const getTwoDigit = (value) => (value > 9 ? value : `0${value}`);

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address?.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export function getInjection(isDarkMode) {
  // for (const [key, wallet] of Object.entries(InjectedWalletTable)) {
  //   if (window.ethereum?.[key as keyof Window['ethereum']]) return wallet
  // }

  if (window.ethereum?.isMetaMask) return { name: "MetaMask" };

  if (!window.ethereum || window.ethereum.isCoinbaseWallet)
    return { name: "Install MetaMask" };

  return { name: "Browser Wallet" };
}

export const getIsMetaMaskWallet = () => getInjection().name === "MetaMask";

export const getIsCoinbaseWallet = () =>
  Boolean(window.ethereum?.isCoinbaseWallet);

export const getIsInjected = () => Boolean(window.ethereum);

function getReason(error) {
  let reason;
  while (error) {
    reason = error.reason ?? error.message ?? reason;
    error = error.error ?? error.data?.originalError;
  }
  return reason;
}

export function didUserReject(error) {
  const reason = getReason(error);
  if (
    error?.code === 4001 ||
    // ethers v5.7.0 wrapped error
    error?.code === "ACTION_REJECTED" ||
    // For Rainbow :
    (reason?.match(/request/i) && reason?.match(/reject/i)) ||
    // For Frame:
    reason?.match(/declined/i) ||
    // For SafePal:
    reason?.match(/cancell?ed by user/i) ||
    // For Trust:
    reason?.match(/user cancell?ed/i) ||
    // For Coinbase:
    reason?.match(/user denied/i) ||
    // For Fireblocks
    reason?.match(/user rejected/i)
    // error instanceof UserRejectedRequestError
  ) {
    return true;
  }
  return false;
}

export function identifyErrorMessage(error) {
  if (didUserReject(error)) {
    toast.error(error?.message);
    return;
  } else if (error?.data?.message) {
    const message = error?.data?.message;
    const msgSplit = message?.split("'");
    const msg =
      msgSplit?.length > 1
        ? msgSplit[1]?.split(":")?.length > 1
          ? msgSplit[1]?.split(":")[1]
          : msgSplit[1]
        : "";
    toast.error(msg || message);
    return;
  } else {
    const reason = getReason(error);
    toast.error(reason);
    return;
  }
}

export function converEthtoUsd(eth, price) {
  return (+eth * +price)?.toFixed(2);
}

export function convertUsdtoEth(dollar, price) {
  return (+dollar / +price)?.toFixed(18);
}

const names = ["wei", "kwei", "mwei", "gwei", "szabo", "finney", "ether"];

export function formatUnits(value, unitName) {
  if (typeof unitName === "string") {
    const index = names.indexOf(unitName);
    if (index !== -1) {
      unitName = 3 * index;
    }
  }
  return formatFixed(value, unitName != null ? unitName : 18);
}

export function formatEther(wei) {
  return formatUnits(wei, 18);
}

export function formatWei(wei) {
  return formatUnits(wei, 0);
}

export function EthtoWei(eth) {
  if (eth) return formatWei(ethers.utils.parseUnits(eth?.toString(), "ether"));
  return eth;
}

export const getWalletBalance = async (
  address,
  web3Provider,
  dispatch = () => null
) => {
  try {
    const walletType = getPersistedConnectionMeta();
    if (walletType && web3Provider && address) {
      // const balance = await web3Provider.getBalance(address);
      // setWalletBalance((+formatEther(balance)).toFixed(2));
      // dispatch(
      //   setWalletBalanceSaga({
      //     currentBalance: balance,
      //     showBalance: (+formatEther(balance)).toFixed(2),
      //   })
      // );
    }
  } catch (err) {
    console.log({ err });
  }
};

export const disconnectWalletCall = (connector, dispatch) => {
  clearConnectorStorage(connector);
  localStorage.clear();
  if (connector && connector.deactivate) {
    connector.deactivate();
  }
  connector.resetState();
  dispatch(resetApp());
};

export function formatDecimalValue(amount) {
  if (amount) {
    let formattedAmount = amount.toString();
    formattedAmount = formattedAmount.replace(/(\.0*|(?<=\.\d*[^0])0*)$/, "");
    return formattedAmount;
  }
}

export const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 25,
    label: '25%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
];