import React from "react";
import PropTypes from "prop-types";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { withRouter } from "../../NonAuthLayout";

// users
// import user4 from "../../../assets/images/users/avatar-1.jpeg";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  // const [menu, setMenu] = useState(false);

  const { userData } = useSelector((state) => state.auth);

  return (
    <React.Fragment>
      {/* <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      > */}
      <button
        className="btn header-item waves-effect"
        // id="page-header-user-dropdown"
      >
        <Link to="/profile" style={{ color: "black" }}>
          <span
            className="d-xl-inline-block ms-1 font-size-15"
            style={{
              paddingRight: "10px",
              color: "#1a1f29",
              fontWeight: "600",
            }}
          >
            {userData ? userData?.name : ""}
          </span>{" "}
          {/* <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
            // style={{ paddingLeft: "10px" }}
          /> */}
          <Avatar
            name={userData ? userData?.name : ""}
            className="rounded-circle text-white"
            // className="circle"
            color="#1a1f29"
            size="40"
          />
        </Link>

        {/* <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i> */}
      </button>
      {/* <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/">
            <i className="uil uil-wallet font-size-18 align-middle me-1 text-muted"></i>
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Settings")}
            <span className="badge bg-soft-success rounded-pill mt-1 ms-2">
              03
            </span>
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="uil uil-lock-alt font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Lock screen")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/login" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu> */}
      {/* </Dropdown> */}
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
