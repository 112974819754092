import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, CardBody, CardTitle, Progress } from "reactstrap";
import moment from "moment";

import CountDown from "./CountDown";
import { fetchIcoInfo } from "../../store/actions";
import { formatDecimalValue } from "../../utils";

import "./BuyCoin.css";

const IcoPhase = () => {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.ico);

  const [ico, setIco] = useState("");

  useEffect(() => {
    dispatch(fetchIcoInfo());
  }, []);

  useEffect(() => {
    if (info) {
      setIco(info?.Ico?.nextPhaseStartDate);
    }
  }, [info]);

  return (
    <React.Fragment>
      <Row className="h-100">
        <Col lg={12} className="ico_details_col">
          <Card className="ico_details h-100">
            <CardBody>
              <div>
                <div className="ico_title">ICO details</div>
                <div
                  className="d-grid"
                  style={{
                    marginTop: "15px",
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    padding: "0 24px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="align-items-left list-unstyled py-2 general_text">
                    Round
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    {info?.Ico?.phaseName}
                  </div>

                  <div className="align-items-left list-unstyled py-2 general_text">
                    Phase Rate
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    {`$ ${info?.Ico?.oneAthdToDollarExpected}`}
                  </div>

                  <div className="align-items-left list-unstyled py-2 general_text">
                    Start Date
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    {moment(info?.Ico?.startDate).format("lll")}
                  </div>

                  <div className="align-items-left list-unstyled py-2 general_text">
                    Raised Amount
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    $ {formatDecimalValue(info?.raisedAmount?.toFixed(2))}
                  </div>

                  <div className="align-items-left list-unstyled py-2 general_text">
                    Target Amount
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    $ {formatDecimalValue(info?.targetAmount?.toFixed(2))}
                  </div>
                </div>

                <hr />
              </div>
              {/* vesting */}
              <div>
                <div className="ico_title">Vesting Details</div>
                <div
                  className="d-grid"
                  style={{
                    marginTop: "15px",
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    padding: "0 24px",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="align-items-left list-unstyled py-2 general_text">
                    Lock-In duration & Vesting rates
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    {info?.vesting?.lockDuration ?? 0}
                  </div>

                  <div className="align-items-left list-unstyled py-2 general_text">
                    Cliff period
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    {`${info?.vesting?.cliff ?? 0}`}
                  </div>

                  <div className="align-items-left list-unstyled py-2 general_text">
                    Token release
                  </div>
                  <div className="align-items-left list-unstyled py-2 buycoin__stage__sub">
                    {`${info?.vesting?.TGE ?? 0} %`}
                  </div>
                </div>

                <hr />
              </div>

              <CountDown />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default IcoPhase;
