/* eslint-disable max-len */
import { put } from "redux-saga/effects";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import axiosMain from "../../../http/axios/axios_main";
import {
  activityLogsFail,
  activityLogsStart,
  activityLogsSuccess,
  addAdminFail,
  addAdminStart,
  addAdminSuccess,
  commonFail,
  commonStart,
  commonSuccess,
  deleteAdminFail,
  deleteAdminStart,
  deleteAdminSuccess,
  editAdminFail,
  editAdminStart,
  editAdminSuccess,
  getAllAdmin,
  getAllAdminFail,
  getAllAdminStart,
  getAllAdminSuccess,
  viewAdminFail,
  viewAdminStart,
  viewAdminSuccess,
} from "../../actions/admin/admin";
import { errorHandler } from "../../../utils";

export function* getAllAdminSaga() {
  yield put(getAllAdminStart());
  try {
    const response = yield axiosMain.get(
      "/admin"
      // `/admin?isDisabled=${params.status === 'Active'}&fromDate=${params.from || ''}&toDate=${params.to || ''}`,
    );
    if (response.status === 200) {
      yield put(getAllAdminSuccess(response.data));
    } else {
      yield put(getAllAdminFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(getAllAdminFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(getAllAdminFail(error.response.data.msg));
      } else {
        yield put(getAllAdminFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(getAllAdminFail("Something went wrong! Please try again."));
    }
  }
}

export function* addAdminSaga(action) {
  const { data } = action.payload;

  yield put(addAdminStart(data));
  try {
    const response = yield axiosMain.post("/admin", data);
    if (response.status === 201) {
      yield put(addAdminSuccess(response.data));
      yield toastr.success("New admin created");
      yield put(getAllAdmin());
    } else {
      yield put(addAdminFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(addAdminFail(error.response.data.msg));
        yield toastr.error("Something went wrong, new admin was not created");
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(addAdminFail(error.response.data.msg));
        yield toastr.error("Something went wrong, new admin was not created");
      } else {
        yield put(addAdminFail("Something went wrong! Please try again."));
        yield toastr.error("Something went wrong, new admin was not created");
      }
    } else {
      yield put(addAdminFail("Something went wrong! Please try again."));
      yield toastr.error("Something went wrong, new admin was not created");
    }
  }
}

export function* deleteAdminSaga(action) {
  const { _id } = action.payload;

  yield put(deleteAdminStart());
  try {
    const response = yield axiosMain.delete(`/admin/${_id}`);
    if (response.status === 200) {
      yield put(deleteAdminSuccess(response.data));
      yield toastr.success("Admin deleted successfully");

      yield put(getAllAdmin());
    } else {
      yield put(deleteAdminFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(deleteAdminFail(error.response.data.msg));
        yield toastr.error("Something went wrong, admin was not deleted");
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(deleteAdminFail(error.response.data.msg));
        yield toastr.error("Something went wrong, admin was not deleted");
      } else {
        yield put(deleteAdminFail("Something went wrong! Please try again."));
        yield toastr.error("Something went wrong, admin was not deleted");
      }
    } else {
      yield put(deleteAdminFail("Something went wrong! Please try again."));
      yield toastr.error("Something went wrong, admin was not deleted");
    }
  }
}

export function* editAdminSaga(action) {
  const { isDisable } = action.payload;
  const { data } = action.payload;
  const { _id } = action.payload;

  yield put(editAdminStart());
  try {
    const response = yield axiosMain.patch(
      `/admin/${_id}`,
      data || { isDisabled: isDisable }
    );
    if (response.status === 200) {
      yield put(editAdminSuccess(response.data));
      yield put(getAllAdmin());

      yield toastr.success("Admin edited successfully");
    } else {
      yield put(editAdminFail("Something went wrong! Please try again."));
      yield toastr.error("Something went wrong, admin was not edited");
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(editAdminFail(error.response.data.msg));
        yield toastr.error("Something went wrong, admin was not edited");
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(editAdminFail(error.response.data.msg));
        yield toastr.error("Something went wrong, admin was not edited");
      } else {
        yield put(editAdminFail("Something went wrong! Please try again."));
        yield toastr.error("Something went wrong, admin was not edited");
      }
    } else {
      yield put(editAdminFail("Something went wrong! Please try again."));
      yield toastr.error("Something went wrong, admin was not edited");
    }
  }
}

export function* activityLogsSaga(action) {
  const { _id } = action.payload;

  yield put(activityLogsStart());
  try {
    const response = yield axiosMain.get(`admin/${_id}/activity-log`);
    if (response.status === 200) {
      yield put(activityLogsSuccess(response.data));
    } else {
      yield put(activityLogsFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(activityLogsFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(activityLogsFail(error.response.data.msg));
      } else {
        yield put(activityLogsFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(activityLogsFail("Something went wrong! Please try again."));
    }
  }
}

export function* viewAdminSaga(action) {
  const { _id } = action.payload;

  yield put(viewAdminStart());
  try {
    const response = yield axiosMain.get(`/admin/${_id}`);
    if (response.status === 200) {
      yield put(viewAdminSuccess(response.data));
    } else {
      yield put(viewAdminFail("Something went wrong! Please try again."));
    }
  } catch (error) {
    if (
      error !== undefined &&
      error.response !== undefined &&
      error.response.status !== undefined
    ) {
      if (error.response.status === 400) {
        yield put(viewAdminFail(error.response.data.msg));
      } else if (
        error.response.data.msg !== undefined &&
        error.response.data.msg !== "" &&
        typeof error.response.data.msg === "string"
      ) {
        yield put(viewAdminFail(error.response.data.msg));
      } else {
        yield put(viewAdminFail("Something went wrong! Please try again."));
      }
    } else {
      yield put(viewAdminFail("Something went wrong! Please try again."));
    }
  }
}

export function* commonSaga(action) {
 
  const {endPoint, method, success, stateObj , payloadData, onCompletion} = action.payload

  yield put(commonStart({stateObj}));
  yield errorHandler({
    endpoint: endPoint,
    successHandler: yield function* (response) {
      const { data } = response;
      // eslint-disable-next-line object-shorthand
      const resObj = {res: data, stateObj: stateObj};   

      //for toast message
      if(success) {
        success(data);
      }
     
      if(onCompletion){
        onCompletion();
      }
      yield put(commonSuccess(resObj));
    },
    failHandler: yield function* (response) {
      yield put(commonFail(response));
    }, 
    failHandlerType: 'CUSTOM',
    apiType: method,
    token: true,
    payload : payloadData
  });
}
