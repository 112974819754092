import React from "react";
import "./WalletButton.css";
import { Spinner } from "reactstrap";

const WalletButton = ({ title, logo, onConnectClick, isLoading }) => {
  if (isLoading) {
    return (
      <>
        <div className="loginCard" onClick={onConnectClick}>
          <div>
            <Spinner size="sm" /> {title}
          </div>
          {logo ? (
            <div>
              <img src={logo} width="30" height="auto" alt="logo" />
            </div>
          ) : null}
        </div>
      </>
    );
  }
  
  return (
    <div className="loginCard" onClick={onConnectClick}>
      <div>{title}</div>
      {logo ? (
        <div>
          <img src={logo} width="30" height="auto" alt="logo" />
        </div>
      ) : null}
    </div>
  );
};

export default WalletButton;
