import * as actionLabels from "../../actionLabels";

export const initialState = {
  // INVITE USER
  inviteUserLoading: false,
  inviteUserErrorMsg: "",
  // GET REFERRAL LIST SAGA
  getReferralListLoading: false,
  getReferralListErrorMsg: "",
  referralList: [],
  // GET CLAIM HISTORY LIST SAGA
  getClaimHistoryListLoading: false,
  getClaimHistoryListErrorMsg: "",
  claimHistoryList: [],
  // CLAIM RERERRAL SAGA
  claimReferralLoading: false,
  claimReferralErrorMsg: "",
};

const profile = (state = initialState, { type, payload }) => {
  switch (type) {
    // INVITE USER
    case actionLabels.INVITE_USER_START:
      return { ...state, inviteUserLoading: true };
    case actionLabels.INVITE_USER_SUCCESS: {
      return {
        ...state,
        inviteUserLoading: false,
        inviteUserErrorMsg: "",
      };
    }
    case actionLabels.INVITE_USER_FAIL: {
      return {
        ...state,
        inviteUserLoading: false,
        inviteUserErrorMsg: payload,
      };
    }

    // GET REFERRAL LIST SAGA
    case actionLabels.GET_REFERRAL_LIST_START:
      return { ...state, getReferralListLoading: true };
    case actionLabels.GET_REFERRAL_LIST_SUCCESS: {
      return {
        ...state,
        getReferralListLoading: false,
        getReferralListErrorMsg: "",
        referralList: payload,
      };
    }
    case actionLabels.GET_REFERRAL_LIST_FAIL: {
      return {
        ...state,
        getReferralListLoading: false,
        getReferralListErrorMsg: payload,
      };
    }

    // GET REFERRAL LIST SAGA
    case actionLabels.GET_CLAIM_HISTORY_LIST_START:
      return { ...state, getClaimHistoryListLoading: true };
    case actionLabels.GET_CLAIM_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        getClaimHistoryListLoading: false,
        getClaimHistoryListErrorMsg: "",
        claimHistoryList: payload,
      };
    }
    case actionLabels.GET_CLAIM_HISTORY_LIST_FAIL: {
      return {
        ...state,
        getClaimHistoryListLoading: false,
        getClaimHistoryListErrorMsg: payload,
      };
    }

    // CLAIM RERERRAL SAGA
    case actionLabels.CLAIM_RERERRAL_START:
      return { ...state, claimReferralLoading: true };
    case actionLabels.CLAIM_RERERRAL_SUCCESS: {
      return {
        ...state,
        claimReferralLoading: false,
        claimReferralErrorMsg: "",
      };
    }
    case actionLabels.CLAIM_RERERRAL_FAIL: {
      return {
        ...state,
        claimReferralLoading: false,
        claimReferralErrorMsg: payload,
      };
    }

    default:
      return state;
  }
};

export default profile;
