import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "../WalletConnectConnector";

export const clearConnectorStorage = (connector) => {
  if (connector instanceof WalletConnectConnector) {
    localStorage.removeItem("walletconnect");
  } else if (connector instanceof WalletLinkConnector) {
    localStorage.removeItem("-walletlink:https://www.walletlink.org:version");
    localStorage.removeItem(
      "-walletlink:https://www.walletlink.org:session:id"
    );
    localStorage.removeItem(
      "-walletlink:https://www.walletlink.org:session:secret"
    );
    localStorage.removeItem(
      "-walletlink:https://www.walletlink.org:session:linked"
    );
    localStorage.removeItem(
      "-walletlink:https://www.walletlink.org:AppVersion"
    );
    localStorage.removeItem("-walletlink:https://www.walletlink.org:Addresses");
    localStorage.removeItem(
      "-walletlink:https://www.walletlink.org:walletUsername"
    );
  }
};
