/* eslint-disable no-unused-vars */
import { put } from "redux-saga/effects";

import { errorHandler } from "../../../utils";
import {
  CLAIM_RERERRAL_URL,
  GET_CLAIM_HISTORY_URL,
  GET_REFERRAL_LIST_URL,
  INVITE_USER_URL,
} from "../../../apis";
import {
  claimReferralFail,
  claimReferralStart,
  claimReferralSuccess,
  getClaimHistoryListFail,
  getClaimHistoryListStart,
  getClaimHistoryListSuccess,
  getReferralListFail,
  getReferralListStart,
  getReferralListSuccess,
  inviteUserFail,
  inviteUserStart,
  inviteUserSuccess,
} from "../../actions";
import { toast } from "react-toastify";

// invite user
export function* inviteUserSaga({ payload }) {
  const { setReferrerEmail, data } = payload;
  yield put(inviteUserStart());
  yield errorHandler({
    endpoint: INVITE_USER_URL,
    successHandler: yield function* (response) {
      yield put(inviteUserSuccess(response?.data));
      toast.success(response?.data?.message);
      setReferrerEmail("");
    },
    failHandler: inviteUserFail,
    apiType: "post",
    payload: data,
    token: localStorage.getItem("token"),
  });
}

// GET_REFERRAL_LIST_SAGA
export function* getReferralListSaga({ payload }) {
  yield put(getReferralListStart());
  yield errorHandler({
    endpoint: GET_REFERRAL_LIST_URL,
    successHandler: yield function* (response) {
      yield put(getReferralListSuccess(response?.data));
    },
    failHandler: getReferralListFail,
    apiType: "get",
    payload: payload,
    token: localStorage.getItem("token"),
  });
}

// GET_REFERRAL_LIST_SAGA
export function* getClaimHistoryListSaga({ payload }) {
  yield put(getClaimHistoryListStart());
  yield errorHandler({
    endpoint: GET_CLAIM_HISTORY_URL,
    successHandler: yield function* (response) {
      yield put(getClaimHistoryListSuccess(response?.data));
    },
    failHandler: getClaimHistoryListFail,
    apiType: "get",
    payload: payload,
    token: localStorage.getItem("token"),
  });
}

// CLAIM_RERERRAL_SAGA
export function* claimReferralSaga({ payload }) {
  yield put(claimReferralStart());
  yield errorHandler({
    endpoint: CLAIM_RERERRAL_URL,
    successHandler: yield function* (response) {
      yield put(claimReferralSuccess(response?.data));
      toast.success(response?.data?.message);
    },
    failHandler: claimReferralFail,
    apiType: "post",
    payload: payload,
    token: localStorage.getItem("token"),
  });
}
