import { initializeConnector } from "@web3-react/core";
import { CoinbaseWallet } from "@web3-react/coinbase-wallet";
import { MetaMask } from "@web3-react/metamask";
import { getIsCoinbaseWallet, getIsInjected, getIsMetaMaskWallet } from ".";
import { isMobile } from "./userAgent";
import { FOX, WALLET } from "../assets/images";
import { Connector } from "@web3-react/types";
import { walletConnectV2Connection } from "./walletconnect";

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

const getShouldAdvertiseMetaMask = () =>
  !getIsMetaMaskWallet() &&
  !isMobile &&
  (!getIsInjected() || getIsCoinbaseWallet());

const getIsGenericInjector = () =>
  getIsInjected() && !getIsMetaMaskWallet() && !getIsCoinbaseWallet();
const getIsMetaMaskBrowser = () => isMobile && getIsMetaMaskWallet();
const getIsCoinbaseWalletBrowser = () => isMobile && getIsCoinbaseWallet();

export const getIsInjectedMobileBrowser = () =>
  getIsCoinbaseWalletBrowser() || getIsMetaMaskBrowser();

export const [web3Injected, web3InjectedHooks] = initializeConnector(
  (actions) => new MetaMask({ actions, onError })
);

export const injectedConnection = {
  getName: () => "Metamask",
  connector: web3Injected,
  hooks: web3InjectedHooks,
  type: "INJECTED",
  getIcon: () => FOX,
  shouldDisplay: () =>
    getIsMetaMaskWallet() ||
    getShouldAdvertiseMetaMask() ||
    getIsGenericInjector(),
  // If on non-injected, non-mobile browser, prompt user to install Metamask
  overrideActivate: () => {
    if (getShouldAdvertiseMetaMask()) {
      window.open("https://metamask.io/", "inst_metamask");
      return true;
    }
    return false;
  },
};

export const [web3CoinbaseWallet, web3CoinbaseWalletHooks] =
  initializeConnector(
    (actions) =>
      new CoinbaseWallet({
        actions,
        options: {
          url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
          appName: "AutoHeads",
          appLogoUrl: FOX,
          reloadOnDisconnect: false,
        },
        onError,
      })
  );
const coinbaseWalletConnection = {
  getName: () => "Coinbase",
  connector: web3CoinbaseWallet,
  hooks: web3CoinbaseWalletHooks,
  type: "COINBASE_WALLET",
  getIcon: () => WALLET,
  shouldDisplay: () =>
    Boolean(
      (isMobile && !getIsInjectedMobileBrowser()) ||
        !isMobile ||
        getIsCoinbaseWalletBrowser()
    ),
  // If on a mobile browser that isn't the coinbase wallet browser, deeplink to the coinbase wallet app
  overrideActivate: () => {
    if (isMobile && !getIsInjectedMobileBrowser()) {
      window.open("https://go.cb-w.com/mtUDhEZPy1", "cbwallet");
      return true;
    }
    return false;
  },
};

export const connections = [
  injectedConnection,
  coinbaseWalletConnection,
  walletConnectV2Connection,
];

export const connectors = {
  injected: injectedConnection,
  walletConnect: walletConnectV2Connection,
  coinbaseWallet: coinbaseWalletConnection,
};

export function getConnection(c) {
  if (c instanceof Connector) {
    const connection = connections.find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error("unsupported connector");
    }
    return connection;
  } else {
    switch (c) {
      case "INJECTED":
        return injectedConnection;
      case "COINBASE_WALLET":
        return coinbaseWalletConnection;
      case "WALLET_CONNECT_V2":
        return walletConnectV2Connection;
      default:
        return;
    }
  }
}
