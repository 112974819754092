import * as actionLabels from "../../actionLabels";

// FOR LOGIN

export const loginStart = (payload) => ({
  type: actionLabels.LOGIN_START,
  payload,
});

export const loginSaga = (payload) => ({
  type: actionLabels.LOGIN_SAGA,
  payload,
});

export const loginSuccess = (payload) => ({
  type: actionLabels.LOGIN_SUCCESS,
  payload,
});

export const loginFail = (payload) => ({
  type: actionLabels.LOGIN_FAIL,
  payload,
});

// FOR SIGNUP

export const signupStart = (payload) => ({
  type: actionLabels.SIGNUP_START,
  payload,
});

export const signupSaga = (payload, handleModal, dispatch, disconnectWallet) => ({
  type: actionLabels.SIGNUP_SAGA,
  payload,
  handleModal,
  dispatch,
  disconnectWallet,
});

export const signupSuccess = (payload) => ({
  type: actionLabels.SIGNUP_SUCCESS,
  payload,
});

export const signupFail = (payload) => ({
  type: actionLabels.SIGNUP_FAIL,
  payload,
});

export const checkUserInfoAvailabilityStart = () => ({
  type: actionLabels.CHECK_USERINFO_AVAIL_START,
});

export const checkUserInfoAvailability = (payload) => ({
  type: actionLabels.CHECK_USERINFO_AVAIL_SAGA,
  payload,
});

export const checkUserInfoAvailabilitySuccess = (payload) => ({
  type: actionLabels.CHECK_USERINFO_AVAIL_SUCCESS,
  payload,
});

export const checkUserInfoAvailabilityFail = (payload) => ({
  type: actionLabels.CHECK_USERINFO_AVAIL_FAIL,
  payload,
});
export const fetchProfileInfoStart = () => ({
  type: actionLabels.FETCH_PROFILE_INFO_START,
});

export const fetchProfileInfo = (disconnectWallet) => ({
  type: actionLabels.FETCH_PROFILE_INFO_SAGA,
  disconnectWallet,
});

export const fetchProfileInfoSuccess = (payload) => ({
  type: actionLabels.FETCH_PROFILE_INFO_SUCCESS,
  payload,
});

export const fetchProfileInfoFail = (payload) => ({
  type: actionLabels.FETCH_PROFILE_INFO_FAIL,
  payload,
});

// WALLET LOGIN
export const walletLoginStart = () => ({
  type: actionLabels.WALLET_LOGIN_START,
});

export const walletLogin = (payload) => ({
  type: actionLabels.WALLET_LOGIN_SAGA,
  payload,
});

export const walletLoginSuccess = (payload) => ({
  type: actionLabels.WALLET_LOGIN_SUCCESS,
  payload,
});

export const walletLoginFail = (payload) => ({
  type: actionLabels.WALLET_LOGIN_FAIL,
  payload,
});

// FOR SIGNATURE

export const signWalletStart = () => ({
  type: actionLabels.SIGN_WALLET_START,
});

export const signWalletSaga = (payload) => ({
  type: actionLabels.SIGN_WALLET_SAGA,
  payload,
});

export const signWalletSuccess = (payload) => ({
  type: actionLabels.SIGN_WALLET_SUCCESS,
  payload,
});

export const signWalletFail = (payload) => ({
  type: actionLabels.SIGN_WALLET_FAIL,
  payload,
});

// FOR RESENDING OTP

export const resendEmailStart = () => ({
  type: actionLabels.RESEND_EMAIL_START,
});

export const resendEmailSaga = (payload) => {
  return {
    type: actionLabels.RESEND_EMAIL_SAGA,
    payload,
  };
};

export const resendEmailSuccess = (payload) => ({
  type: actionLabels.RESEND_EMAIL_SUCCESS,
  payload,
});

export const resendEmailFail = (payload) => ({
  type: actionLabels.RESEND_EMAIL_FAIL,
  payload,
});

// EDIT PROFILE

export const editProfileStart = () => ({
  type: actionLabels.EDIT_PROFILE_START,
});

export const editProfileSaga = (payload, disconnectWallet) => ({
  type: actionLabels.EDIT_PROFILE_SAGA,
  payload,
  disconnectWallet,
});

export const editProfileSuccess = (payload) => ({
  type: actionLabels.EDIT_PROFILE_SUCCESS,
  payload,
});

export const editProfileFail = (payload) => ({
  type: actionLabels.EDIT_PROFILE_FAIL,
  payload,
});

export const updateTokenClaimStart = () => ({
  type: actionLabels.UPDATE_TOKEN_CLAIM_START,
});

export const updateTokenClaimSaga = (payload, disconnectWallet) => ({
  type: actionLabels.UPDATE_TOKEN_CLAIM_SAGA,
  payload,
  disconnectWallet,
});

export const updateTokenClaimSuccess = (payload) => ({
  type: actionLabels.UPDATE_TOKEN_CLAIM_SUCCESS,
  payload,
});

export const updateTokenClaimFail = (payload) => ({
  type: actionLabels.UPDATE_TOKEN_CLAIM_FAIL,
  payload,
});

export const updateTokenCountStart = () => ({
  type: actionLabels.UPDATE_TOKEN_COUNT_START,
});

export const updateTokenCountSaga = (payload, disconnectWallet) => ({
  type: actionLabels.UPDATE_TOKEN_COUNT_SAGA,
  payload,
  disconnectWallet,
});

export const updateTokenCountSuccess = (payload) => ({
  type: actionLabels.UPDATE_TOKEN_COUNT_SUCCESS,
  payload,
});

export const updateTokenCountFail = (payload) => ({
  type: actionLabels.UPDATE_TOKEN_COUNT_FAIL,
  payload,
});

export const networkSwitchStart = (payload) => ({
  type: actionLabels.NETWORK_SWITCH_START,
});

export const networkSwitchSaga = (payload) => ({
  type: actionLabels.NETWORK_SWITCH_SAGA,
  payload,
});

export const networkSwitchSuccess = (payload) => ({
  type: actionLabels.NETWORK_SWITCH_SUCCESS,
  payload,
});

export const networkSwitchFail = (payload) => ({
  type: actionLabels.NETWORK_SWITCH_FAIL,
  payload,
});

// UPDATING TOKEN COUNT

export const updateTokenCount = (payload) => ({
  type: actionLabels.UPATE_TOKEN_COUNT,
  payload,
});

// LOGOUT

export const logoutStart = (payload) => ({
  type: actionLabels.LOGOUT_START,
  payload,
});
export const logout = (payload) => ({
  type: actionLabels.LOGOUT_SAGA,
  payload,
});

export const logoutSuccess = (payload) => ({
  type: actionLabels.LOGOUT_SUCCESS,
  payload,
});

export const logoutFailed = (payload) => ({
  type: actionLabels.LOGOUT_FAIL,
  payload,
});

// // FOR ACTIVATE PASSWORD

// export const activatePasswordStart = () => ({
//   type: actionLabels.ACTIVATE_PASSWORD_START,
// });

// export const activatePassword = (payload) => ({
//   type: actionLabels.ACTIVATE_PASSWORD_SAGA,
//   payload,
// });

// export const activatePasswordSuccess = (payload) => ({
//   type: actionLabels.ACTIVATE_PASSWORD_SUCCESS,
//   payload,
// });

// export const activatePasswordFail = (payload) => ({
//   type: actionLabels.ACTIVATE_PASSWORD_FAIL,
//   payload,
// });

// // FOR RESENDING OTP

// export const resendOtpStart = () => ({
//   type: actionLabels.RESEND_OTP_START,
// });

// export const resendOtp = (payload) => ({
//   type: actionLabels.RESEND_OTP_SAGA,
//   payload,
// });

// export const resendOtpSuccess = (payload) => ({
//   type: actionLabels.RESEND_OTP_SUCCESS,
//   payload,
// });

// export const resendOtpFail = (payload) => ({
//   type: actionLabels.RESEND_OTP_FAIL,
//   payload,
// });

// // FOR RESETTING PASSWORD

// export const resetPasswordStart = () => ({
//   type: actionLabels.RESET_PASSWORD_START,
// });

// export const resetPassword = (payload) => ({
//   type: actionLabels.RESET_PASSWORD_SAGA,
//   payload,
// });

// export const resetPasswordSuccess = (payload) => ({
//   type: actionLabels.RESET_PASSWORD_SUCCESS,
//   payload,
// });

// export const resetPasswordFail = (payload) => ({
//   type: actionLabels.RESET_PASSWORD_FAIL,
//   payload,
// });

// export const authenticationValidator = () => ({
//   type: actionLabels.AUTHENTICATION_VALIDATOR,
// });

// export const setDefault = () => ({
//   type: actionLabels.SET_DEFAULT,
// });
