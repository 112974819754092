import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
// import errorImg from "../../assets/images/register-img.png";

const EmailVerificationSuccess = () => {
  useEffect(() => {
    document.body.className = "page_background_color";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div>
        <Container>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 77px)",
              paddingBottom: "77px",
            }}
          >
            <Col md={12}>
              <div className="text-center">
                <h4
                  className="text-uppercase"
                  style={{ fontFamily: "'Montserrat'", color: "black" }}
                >
                  Congratulations! your email has been verified successfully
                </h4>
                {/* <p
                  className=""
                  style={{
                    color: "#fff",
                  }}
                >
                  Connect wallet to continue
                </p> */}
                <div className="mt-5">
                  <Link
                    className="btn btn-primary waves-effect"
                    style={{
                      background: "black",
                      borderRadius: "4px",
                      border: "none",
                    }}
                    to="/login"
                  >
                    Click here to continue
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailVerificationSuccess;
