export const INVITE_USER_SAGA = "INVITE_USER_SAGA";
export const INVITE_USER_START = "INVITE_USER_START";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAIL = "INVITE_USER_FAIL";

export const GET_REFERRAL_LIST_SAGA = "GET_REFERRAL_LIST_SAGA";
export const GET_REFERRAL_LIST_START = "GET_REFERRAL_LIST_START";
export const GET_REFERRAL_LIST_SUCCESS = "GET_REFERRAL_LIST_SUCCESS";
export const GET_REFERRAL_LIST_FAIL = "GET_REFERRAL_LIST_FAIL";

export const GET_CLAIM_HISTORY_LIST_SAGA = "GET_CLAIM_HISTORY_LIST_SAGA";
export const GET_CLAIM_HISTORY_LIST_START = "GET_CLAIM_HISTORY_LIST_START";
export const GET_CLAIM_HISTORY_LIST_SUCCESS = "GET_CLAIM_HISTORY_LIST_SUCCESS";
export const GET_CLAIM_HISTORY_LIST_FAIL = "GET_CLAIM_HISTORY_LIST_FAIL";

export const CLAIM_RERERRAL_SAGA = "CLAIM_RERERRAL_SAGA";
export const CLAIM_RERERRAL_START = "CLAIM_RERERRAL_START";
export const CLAIM_RERERRAL_SUCCESS = "CLAIM_RERERRAL_SUCCESS";
export const CLAIM_RERERRAL_FAIL = "CLAIM_RERERRAL_FAIL";
