import * as actionLabels from '../../actionLabels';

// FOR GET ALL ADMIN
export const getAllAdminStart = payload => ({
  type: actionLabels.GET_ALL_ADMIN_START,
  payload,
});

export const getAllAdmin = payload => ({
  type: actionLabels.GET_ALL_ADMIN_SAGA,
  payload,
});

export const getAllAdminSuccess = payload => ({
  type: actionLabels.GET_ALL_ADMIN_SUCCESS,
  payload,
});

export const getAllAdminFail = payload => ({
  type: actionLabels.GET_ALL_ADMIN_FAIL,
  payload,
});

// FOR ADDING ADMIN
export const addAdminStart = payload => ({
  type: actionLabels.ADD_ADMIN_START,
  payload,
});

export const addAdmin = payload => ({
  type: actionLabels.ADD_ADMIN_SAGA,
  payload,
});

export const addAdminSuccess = payload => ({
  type: actionLabels.ADD_ADMIN_SUCCESS,
  payload,
});

export const addAdminFail = payload => ({
  type: actionLabels.ADD_ADMIN_FAIL,
  payload,
});

// FOR DELETING ADMIN
export const deleteAdminStart = payload => ({
  type: actionLabels.DELETE_ADMIN_START,
  payload,
});

export const deleteAdmin = payload => ({
  type: actionLabels.DELETE_ADMIN_SAGA,
  payload,
});

export const deleteAdminSuccess = payload => ({
  type: actionLabels.DELETE_ADMIN_SUCCESS,
  payload,
});

export const deleteAdminFail = payload => ({
  type: actionLabels.DELETE_ADMIN_FAIL,
  payload,
});

// FOR ADMIN EDIT

export const editAdminStart = payload => ({
  type: actionLabels.EDIT_ADMIN_START,
  payload,
});

export const editAdmin = payload => ({
  type: actionLabels.EDIT_ADMIN_SAGA,
  payload,
});

export const editAdminSuccess = payload => ({
  type: actionLabels.EDIT_ADMIN_SUCCESS,
  payload,
});

export const editAdminFail = payload => ({
  type: actionLabels.EDIT_ADMIN_FAIL,
  payload,
});

// FOR ACTIVITY LOGS

export const activityLogsStart = payload => ({
  type: actionLabels.ACTIVITY_LOGS_START,
  payload,
});

export const activityLogs = payload => ({
  type: actionLabels.ACTIVITY_LOGS_SAGA,
  payload,
});

export const activityLogsSuccess = payload => ({
  type: actionLabels.ACTIVITY_LOGS_SUCCESS,
  payload,
});

export const activityLogsFail = payload => ({
  type: actionLabels.ACTIVITY_LOGS_FAIL,
  payload,
});

// FOR VIEW ADMIN

export const viewAdminStart = payload => ({
  type: actionLabels.VIEW_ADMIN_START,
  payload,
});

export const viewAdmin = payload => ({
  type: actionLabels.VIEW_ADMIN_SAGA,
  payload,
});

export const viewAdminSuccess = payload => ({
  type: actionLabels.VIEW_ADMIN_SUCCESS,
  payload,
});

export const viewAdminFail = payload => ({
  type: actionLabels.VIEW_ADMIN_FAIL,
  payload,
});

export const commonStart = payload => ({
  type: actionLabels.COMMON_START,
  payload,
});

export const commonSaga = payload => ({
  type: actionLabels.COMMON_SAGA,
  payload,
});

export const commonFail = payload => ({
  type: actionLabels.COMMON_FAIL,
  payload,
});

export const commonSuccess = payload => ({
  type: actionLabels.COMMON_SUCCESS,
  payload,
});