import { put } from "redux-saga/effects";
import axios from "../../http";
import { loginFail, resetApp, showResendEmailModal } from "../../store/actions";

// for testing

export default function* errorHandler({
  endpoint,
  successHandler,
  failHandler,
  payload = {},
  apiType = "",
  token = false,
  disconnectWallet,
  failHandlerType
}) {
  if (apiType.trim() === "") {
    throw new Error("apiType is require");
  }
  try {
    let response;
    const config = {
      headers: {
        "ngrok-skip-browser-warning": "69420",
      },
    };


    if (!token) {
      if (apiType === "get") {
        response = yield axios.get(endpoint, config);
      } else if (apiType === "post") {
        response = yield axios.post(endpoint, payload, config);
      } else if (apiType === "put") {
        response = yield axios.put(endpoint, payload, config);
      } else if (apiType === "delete") {
        response = yield axios.delete(endpoint, config);
      } else if (apiType === "patch") {
        response = yield axios.patch(endpoint, payload, config);
      }
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
      };
      // eslint-disable-next-line no-lonely-if
      if (apiType === "get") {
        response = yield axios.get(endpoint, config);
      } else if (apiType === "post") {
        response = yield axios.post(endpoint, payload, config);
      } else if (apiType === "put") {
        response = yield axios.put(endpoint, payload, config);
      } else if (apiType === "delete") {
        response = yield axios.delete(endpoint, config);
      } else if (apiType === "patch") {
        response = yield axios.patch(endpoint, payload, config);
      }
    }
    if (
      response &&
      (response.status === 200 || response.status === 201) &&
      response.data

      // &&response.data.result &&
      // response.data.result === 1
    ) {
      yield successHandler(response.data);
    } else if (response !== undefined && response.status !== undefined) {
      if (
        response.data.msg !== undefined &&
        response.data.msg !== "" &&
        typeof response.data.msg === "string"
      ) {
        yield put(failHandler(response.data.msg));
      } else {
        yield put(failHandler("Server error! Please try again."));
      }
    } else {
      yield put(failHandler("Something went wrong! Please try again."));
    }
  } catch (error) {
    console.log(error)
    if (
      error !== undefined &&
      error.response !== undefined
      //&& error.response.status !== undefined
    ) {
      if (failHandlerType === 'CUSTOM') {   
        if (error.response.status === 401) {
          yield failHandler(error?.response?.data?.error?.message);
        } else {
        yield failHandler(error?.response?.data?.error?.errors[0]);
      }}
      if (error.response.status === 400) {
        yield put(failHandler(error.response.data.error.message));
      }
      if (error.response.status === 500) {
        yield put(failHandler(error.response.data.error.errors[0]));
      }
      if (error.response.status === 422) {
        if (error.response.data.error.errors) {
          yield put(failHandler(error.response.data.error.errors[0]));
        } else {
          yield put(failHandler(error.response.data.error.message));
        }
      }

      if (error.response.status === 401) {
        if (
          error.response.data.error.errors &&
          error.response.data.error.errors.message
        ) {
          yield put(failHandler(error.response.data.error.errors[0]));
        } else if (
          error.response.data.error.errors &&
          error.response.data.error.message
        ) {
          yield put(
            failHandler({
              message: error.response.data.error.message,
              data: error.response.data.error.errors[0],
            })
          );
          if (error.response.data.error.errors[0].isEmailVerified === false)
            yield put(showResendEmailModal());
          return;
        } else {
          yield put(failHandler(error.response.data.error.message));
        }

        // localStorage.setItem("shouldEagerConnect", false);
        // localStorage.setItem("token", "");

        // disconnectWallet?.();
        // yield put(loginFail({ message: "Unauthorized!" }));
        // yield put(resetApp());
        // yield put(
        //   logoutSuccess({ msg: "Please Authenticated!", msgStatus: "fail" })
        // );
      } else if (
        error.response.data !== undefined &&
        error.response.data !== "" &&
        typeof error.response.data === "string"
      ) {
        if (
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.type
        ) {
          yield put(
            failHandler({
              type: error.response.data.data.type,
              msg: error.response.data.error.message,
            })
          );
        } else {
          yield put(failHandler(error.response.data.error.message));
        }
      } else {
        // yield put(failHandler("Server error! Please try again."));
        // console.log("server down...");
      }
    } else {
      yield put(failHandler("Something went wrong! Please try again."));
    }
  }
}
