import { toast } from "react-toastify";
import { signWalletSuccess, walletLoginSuccess } from "../store/actions";

export const signMessage = async (
  provider,
  account,
  dispatch = () => null,
  setProfileOpen = () => null,
  setSignedMessage = () => null,
  setSignature = () => null,
  menuClose = () => null,
  setError = () => null
) => {
  if (!provider) return;
  try {
    const walletAddress = account;
    const storedSign = localStorage.getItem("signature");
    let signature = "";
    if (storedSign) {
      signature = storedSign;
    } else {
      signature = await provider.send("personal_sign", [
        process.env.REACT_APP_SIGNPHRASE,
        account,
      ]);
    }

    dispatch(
      signWalletSuccess({
        signature: signature?.result || signature,
        msg: "signed successfully",
      })
    );

    localStorage.setItem("signature", signature?.result || signature);
    setSignedMessage(process.env.REACT_APP_SIGNPHRASE);
    setSignature(signature?.result || signature);
    dispatch(
      walletLoginSuccess({
        // requestBody: {
        walletAddress,
        signature: signature?.result || signature,
        // },
        onSuccess: () => setProfileOpen(true),
        onError: () => null,
      })
    );
    menuClose(false);
  } catch (error) {
    const signature = localStorage.getItem("signature");
    if (error?.message === "User denied message signature" && signature) {
      setSignedMessage(process.env.REACT_APP_SIGNPHRASE);
      setSignature(signature?.result || signature);
      dispatch(
        walletLoginSuccess({
          // requestBody: {
          walletAddress: account,
          signature: signature?.result || signature,
          // },
          onSuccess: () => setProfileOpen(true),
          onError: () => null,
        })
      );
      menuClose(false);
    } else {
      console.log({ error });
      toast.error(error?.message);
      setError(error);
    }
  }
};
