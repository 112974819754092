import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import {
  watchAuthentication,
  watchIco,
  watchLayout,
  watchSubmitFeedback,
  watchReferral,
  watchAdmin,
} from "./store/sagas";

import rootReducer from "./store/reducer";

const composeEnhancers =
  process.env.REACT_APP_NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // eslint-disable-next-line no-underscore-dangle
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })
    : compose;

const sagaMiddleware = createSagaMiddleware();

const store = composeEnhancers
  ? createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))
  : createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(watchAuthentication);

sagaMiddleware.run(watchLayout);

sagaMiddleware.run(watchSubmitFeedback);

sagaMiddleware.run(watchIco);

sagaMiddleware.run(watchReferral);

sagaMiddleware.run(watchAdmin);

const persistor = persistStore(store);

export { store, persistor };
