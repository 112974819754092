import React from "react";
import Tooltip from "react-simple-tooltip";

const TrimName = (name, limit) => {
  if (name?.length > limit) {
    const truncatedText = name;
    return <Tooltip content={truncatedText} zIndex={9999} fontSize="12" placement="right">{`${name.slice(0, limit)}...${name.slice(-5)}`}</Tooltip>;
  } else {
    return name;
  }
};

export default TrimName;
