import * as actionLabels from "../../actionLabels";

export const initialState = {
  isLoading: false,
  msg: "",
  msgStatus: "",
  info: null,
  publicTransaction: null,
  privateTransaction: null,
  closedTransaction: null,
  isTransRecordLoading: false,
  proofData: null,
  proofLoader: false,
  nextPhaseStartDate: "",
  gasFee: null,
  gasFeeLoader: false,
  allTransaction: [],
  transactionLoader: false,
  dollarRate: 0,
  txnDetailLoader: false,
  txnDetails: null,
};

const helpCenterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.FETCH_ICO_INFO_START:
      state = { ...state, isLoading: true };
      break;
    case actionLabels.FETCH_ICO_INFO_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        info: payload?.items,
        nextPhaseStartDate: payload
          ? payload?.items?.Ico?.nextPhaseStartDate
          : state?.nextPhaseStartDate,
      };

      break;
    case actionLabels.FETCH_ICO_INFO_FAIL:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "fail",
        isLoading: false,
      };
      break;
    case actionLabels.POST_TRANS_HASH_START:
      state = { ...state, isTransRecordLoading: true };
      break;
    case actionLabels.POST_TRANS_HASH_SUCCESS:
      state = {
        ...state,
        isTransRecordLoading: false,
        msgStatus: "success",
        msg: payload.message,
      };
      break;
    case actionLabels.POST_TRANS_HASH_FAIL:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "fail",
        isTransRecordLoading: false,
      };
      break;

    case actionLabels.RECORD_CLAIM_TOKEN_START:
      state = { ...state, isLoading: true };
      break;
    case actionLabels.RECORD_CLAIM_TOKEN_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        msgStatus: "success",
        msg: payload.message,
      };
      break;
    case actionLabels.RECORD_CLAIM_TOKEN_FAIL:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "fail",
        isLoading: false,
      };
      break;

    case actionLabels.RECORD_PUBLIC_SALE_START:
      state = { ...state, isTransRecordLoading: true };
      break;
    case actionLabels.RECORD_PUBLIC_SALE_SUCCESS:
      state = {
        ...state,
        isTransRecordLoading: false,
        msgStatus: "success",
        msg: payload.message,
      };
      break;
    case actionLabels.RECORD_PUBLIC_SALE_FAIL:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "fail",
        isTransRecordLoading: false,
      };
      break;
    case actionLabels.FETCH_TRANSACTIONS_START:
      state = { ...state, transactionLoader: true, allTransaction: [] };
      break;
    case actionLabels.FETCH_TRANSACTIONS_SUCCESS:
      let publicTransaction = state.publicTransaction;
      let privateTransaction = state.privateTransaction;
      let closedTransaction = state.closedTransaction;

      if (payload.items ? payload.items[0].pk === "PUBLIC_SALE" : false) {
        publicTransaction = payload.items;
      }

      if (payload.items ? payload.items[0].pk === "PRIVATE_SALE" : false) {
        privateTransaction = payload.items;
      }

      if (payload.items ? payload.items[0].pk === "CLOSED_SALE" : false) {
        closedTransaction = payload.items;
      }

      state = {
        ...state,
        transactionLoader: false,
        allTransaction: payload?.items,
        publicTransaction,
        privateTransaction,
        closedTransaction,
      };
      break;
    case actionLabels.FETCH_TRANSACTIONS_FAIL:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "fail",
        transactionLoader: false,
      };
      break;
    case actionLabels.GET_PROOF_START:
      state = { ...state, proofLoader: true };
      break;
    case actionLabels.GET_PROOF_SUCCESS:
      state = {
        ...state,
        proofLoader: false,
        proofData: payload,
      };
      break;
    case actionLabels.GET_PROOF_FAIL:
      state = {
        ...state,
        proofData: payload,
        proofLoader: false,
      };
      break;
    case actionLabels.GET_GAS_FEE_START:
      state = { ...state, gasFeeLoader: true };
      break;
    case actionLabels.GET_GAS_FEE_SUCCESS:
      state = {
        ...state,
        gasFeeLoader: false,
        gasFee: payload?.items?.gasPrice,
      };
      break;
    case actionLabels.GET_GAS_FEE_FAIL:
      state = {
        ...state,
        gasFee: payload,
        gasFeeLoader: false,
      };
      break;
    case actionLabels.RESET_MESSAGE_ICO:
      state = { ...state, msg: "", msgStatus: "" };
      break;

    case actionLabels.SET_DOLLAR_RATE:
      state = { ...state, dollarRate: payload };
      break;
    case actionLabels.GET_TXN_DETAILS_START:
      state = { ...state, txnDetailLoader: true , txnDetails: null };
      break;
    case actionLabels.GET_TXN_DETAILS_SUCCESS:
      state = {
        ...state,
        txnDetailLoader: false,
        txnDetails: payload?.items?.transactionDetails,
      };
      break;

    case actionLabels.GET_TXN_DETAILS_FAIL:
      state = {
        ...state,
        txnDetails: payload,
        txnDetailLoader: false,
      };
    default:
      state = { ...state };

  }
  return state;
};

export default helpCenterReducer;
