import React, { useState, useEffect, useCallback } from "react";

import { Row, Col, Card, CardBody, Button, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useWeb3React } from "@web3-react/core";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "../../components/UI";
import "./Contact.css";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { resetMessageFeedback, submitFeedback } from "../../store/actions";
import { disconnectWalletCall } from "../../utils";
const toastSettings = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ContactUs = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const { connector } = useWeb3React();
  const { msg, msgStatus, isLoading } = useSelector(
    (state) => state.helpCenter
  );

  const disconnectWallet = useCallback(async () => {
    disconnectWalletCall(connector, dispatch);
  }, [connector]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (msgStatus === "success") {
      toast.success(msg, toastSettings);
    } else if (msgStatus === "fail") {
      toast.error(msg, toastSettings);
    }
    if (msg) {
      dispatch(resetMessageFeedback());
    }
  }, [msg, dispatch, msgStatus]);
  if (isLoading)
    return (
      <>
        <div className="page-content">
          <Spinner />
        </div>
      </>
    );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Row style={{ padding: "50px 0px" }}>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              <Breadcrumbs
                title="Forms"
                breadcrumbItem="Reach out to us!"
                className="contact__title"
              />

              <Card style={{ padding: "20px 80px" }}>
                <CardBody>
                  <AvForm>
                    <div className="mb-3">
                      <label className="form-label buycoin__lable__title">
                        Subject
                      </label>
                      <AvField
                        name="subject"
                        // label="Subject"
                        type="text"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                        onChange={(e) => setSubject(e.target.value)}
                        errorMessage="Please enter the subject to proceed."
                        validate={{ required: { value: true } }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label buycoin__lable__title">
                        Message
                      </label>
                      <AvField
                        name="message"
                        type="textarea"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                        rows="10"
                        maxLength="256"
                        onChange={(e) => setMessage(e.target.value)}
                        errorMessage="Please enter a message to proceed."
                        validate={{ required: { value: true } }}
                      />
                    </div>

                    <Col>
                      <Button
                        // color="primary"
                        type="submit"
                        className="w-lg w-100  primary_btn"
                        disabled={subject && message ? false : true}
                        // style={{
                        //   backgroundColor: "#012611",
                        //   borderRadius: "4px",
                        //   border: "none",
                        //   fontWeight: "500",
                        //   fontSize: "16px",
                        // }}
                        onClick={() => {
                          dispatch(
                            submitFeedback(
                              {
                                subject,
                                message,
                              },
                              disconnectWallet
                            )
                          );
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </React.Fragment>
  );
};

export default ContactUs;
