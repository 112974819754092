import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Label, Input } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";

import "./BuyCoin"; // Make sure the CSS file includes the custom radio button styles

const CoinPaymentForm = ({
  TotalEthToSend,
  TotalUSD,
  setPaymentMethod,
  paymentMethod,
  setVisible,
}) => {
  const { userData } = useSelector((state) => state.auth);

  const [payableCoinValue, setPayableCoinValue] = useState(0);

  const handlePaymentMethod = (method) => {
    setVisible(false);
    setPaymentMethod(method);
  };

  useEffect(() => {
    if (paymentMethod === "USD") {
      setPayableCoinValue(TotalUSD);
    } else {
      setPayableCoinValue(`${TotalEthToSend} `);
    }
  }, [TotalEthToSend, TotalUSD, paymentMethod]);

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={12}>
                <div className="mt-2">
                  <div className="mb-4">
                    <Label
                      className="form-label buycoin__lable__title"
                      htmlFor="formrow-firstname-input"
                    >
                      Wallet Address
                      {"  "}
                    </Label>
                    <Row>
                      <Col>
                        <Input
                          type="text"
                          defaultValue={userData && userData.walletAddress}
                          className="form-control coin__payment__form buycoin__lable__title "
                          id="formrow-firstname-input"
                          disabled={true}
                          readOnly
                        ></Input>
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-4">
                    <Label
                      className="form-label buycoin__lable__title"
                      htmlFor="formrow-firstname-input"
                    >
                      Payment Method
                    </Label>
                    <Row>
                      <Col>
                        <div className="radio-container">
                          <input
                            type="radio"
                            id="paywithcrypto"
                            name="paymentMethod"
                            value="POL"
                            checked={paymentMethod === "POL"}
                            onChange={() => handlePaymentMethod("POL")}
                            className="radio-button"
                          />
                          <label
                            htmlFor="paywithcrypto"
                            className={`radio-label ${
                              paymentMethod === "POL" ? "active" : "inactive"
                            }`}
                          >
                            Pay with Crypto
                          </label>

                          <input
                            type="radio"
                            id="paywithfiat"
                            name="paymentMethod"
                            value="USD"
                            checked={paymentMethod === "USD"}
                            onChange={() => handlePaymentMethod("USD")}
                            className="radio-button"
                          />
                          <label
                            htmlFor="paywithfiat"
                            className={`radio-label ${
                              paymentMethod === "USD" ? "active" : "inactive"
                            }`}
                          >
                            Pay with Fiat
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col la={12}>
                      <AvForm>
                        <div className="mb-4 mt-2">
                          <Label
                            className="form-label buycoin__lable__title"
                            htmlFor="formrow-email-input"
                          >
                            Payable Coin
                          </Label>
                          <div className="d-flex">
                            <Input
                              type="text"
                              value={`${payableCoinValue} ${paymentMethod}`}
                              defaultValue={0.0}
                              className="form-control fw-bold coin__payment__form"
                              id="formrow-firstname-input payable_coin_input"
                              style={{
                                background: "#FFF",
                                cursor: "default",
                              }}
                              readOnly
                            ></Input>
                          </div>
                        </div>
                      </AvForm>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default React.memo(CoinPaymentForm);
