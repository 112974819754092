import { CardBody } from "reactstrap";
import { MDBDataTableV5 } from "mdbreact";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";

import { referralRecordColumns } from "./ReferralColumn";

const ReferralRecord = () => {
  const [rowsData, setRowsData] = useState([]);
  const { referralList } = useSelector((state) => state?.referral);

  const updateTableData = () => {
    let allTrans = [];
    referralList?.items.forEach((item, idx) => {
      allTrans.push({
        ...item,
        srn: idx + 1,
        email: item?.userId?.email,
        date: moment(item?.createdAt).format("DD/MM/yyyy, HH:mm") ?? "-",
        status: item?.rewardAmount > 0 ? "Active" : "Inactive",
        amount: item?.rewardAmount,
      });
    });
    setRowsData(allTrans);
  };

  useEffect(() => {
    if (referralList?.items?.length > 0) {
      updateTableData();
    }
  }, [referralList]);

  return (
    <>
      <div className="container-fluid">
        <CardBody>
          <MDBDataTableV5
            responsive
            striped
            hover
            bordered
            data={{
              columns: referralRecordColumns,
              rows: rowsData,
            }}
            noRecordsFoundLabel={"No records found."}
            entries={10}
            searching={false}
            entriesOptions={[5, 10, 20]}
            sortable={true}
            pagesAmount={10}
            style={{ fontSize: "14px" }}
            fullPagination
          />
        </CardBody>
      </div>
    </>
  );
};

export default ReferralRecord;
