export const GET_ALL_ADMIN_SAGA = 'GET_ALL_ADMIN_SAGA';
export const GET_ALL_ADMIN_START = 'GET_ALL_ADMIN_START';
export const GET_ALL_ADMIN_SUCCESS = 'GET_ALL_ADMIN_SUCCESS';
export const GET_ALL_ADMIN_FAIL = 'GET_ALL_ADMIN_FAIL';

export const ADD_ADMIN_SAGA = 'ADD_ADMIN_SAGA';
export const ADD_ADMIN_START = 'ADD_ADMIN_START';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL';

export const DELETE_ADMIN_SAGA = 'DELETE_ADMIN_SAGA';
export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';

export const EDIT_ADMIN_SAGA = 'EDIT_ADMIN_SAGA';
export const EDIT_ADMIN_START = 'EDIT_ADMIN_START';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_FAIL = 'EDIT_ADMIN_FAIL';

export const ACTIVITY_LOGS_SAGA = 'ACTIVITY_LOGS_SAGA';
export const ACTIVITY_LOGS_START = 'ACTIVITY_LOGS_START';
export const ACTIVITY_LOGS_SUCCESS = 'ACTIVITY_LOGS_SUCCESS';
export const ACTIVITY_LOGS_FAIL = 'ACTIVITY_LOGS_FAIL';

export const VIEW_ADMIN_SAGA = 'VIEW_ADMIN_SAGA';
export const VIEW_ADMIN_START = 'VIEW_ADMIN_START';
export const VIEW_ADMIN_SUCCESS = 'VIEW_ADMIN_SUCCESS';
export const VIEW_ADMIN_FAIL = 'VIEW_ADMIN_FAIL';

export const COMMON_SAGA = 'COMMON_SAGA';
export const COMMON_START = 'COMMON_START';
export const COMMON_FAIL = 'COMMON_FAIL';
export const COMMON_SUCCESS = 'COMMON_SUCCESS';