import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import auth from "./auth/auth";
import modal from "./modal/modal";
import layout from "./layout/layout";
import admin from "./admin/admin";
import user from "./user/user";
import profile from "./profile/profile";
import dashboard from "./dashboard/dashboard";
import helpCenter from "./help-center/helpCenter";
import ico from "./ico/ico";

import referral from "./referral/referral";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "Layout", "ico"],
};

const allReducers = combineReducers({
  auth,
  modal,
  layout,
  admin,
  user,
  profile,
  dashboard,
  helpCenter,
  ico,
  referral,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return allReducers(state, action);
};

export default persistReducer(persistConfig, rootReducer);
