import React, { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { connections } from "../../utils/connectors";
import { disconnectWalletCall } from "../../utils";
import Option from "./Option.jsx";
import { withRouter } from "../../components/NonAuthLayout.js";
import { connect } from "react-redux";
import {
  hideResendEmailModal,
  checkUserInfoAvailability,
  loginSaga,
  signWalletSuccess,
  walletLoginStart,
  walletLoginSuccess,
  walletLoginFail,
} from "../../store/actions";
import SignupRedirectModal from "../../components/Modal/SignupRedirectModal.js";
import ResendMailModal from "../../components/Modal/ResendMailModal.js";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo/login.png";

const Login = ({ menuClose, isOpen = false }) => {
  const { connector, account, isActive } = useWeb3React();
  const [modal_openClose, setmodal_openClose] = useState(false);
  const [isWalletSigned, setIsWalletSigned] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);
  const [signature, setSignature] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpenClose = (value) => {
    setmodal_openClose(value);
  };

  const { isAuthenticated, walletAddress, walletAvailable, isLoading } =
    useSelector((state) => state.auth);
  const { isEmailResend } = useSelector((state) => state.modal);

  const [connectedWallet, setConnectedWallet] = useState("");
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [signedMessage, setSignedMessage] = useState("");

  const modalHandler = () => {
    setShouldRegister(!shouldRegister);
    if (shouldRegister) {
      window.location.reload(false);
      // setIsWalletClick(false);
    }
  };

  const handleResendEmail = () => {
    if (isEmailResend) {
      dispatch(hideResendEmailModal());
      window.location.reload(false);
      // setIsWalletClick(false);
    }
  };

  const disconnectWallet = useCallback(async () => {
    disconnectWalletCall(connector, dispatch);
    setConnectedWallet("");
    setLoading(false);
  }, [connector]);

  useEffect(() => {
    if (account) {
      handleOpenClose(!modal_openClose);
    }
    if (isWalletSigned) {
      dispatch(checkUserInfoAvailability(`walletAddress=${account}`));
    }

    if (isWalletSigned && !walletAvailable && walletAvailable !== null) {
      const signature = localStorage.getItem("signature");
      const data = {
        signature: signature?.result || signature,
        loginType: "signature",
        data: process.env.REACT_APP_SIGNPHRASE,
        walletAddress: account,
      };
      dispatch(loginSaga({ payload: data, navigate }));
      // history.push("/dashboard");
    }
    if (isWalletSigned && walletAvailable && walletAddress) {
      modalHandler();
    }
  }, [isWalletSigned, walletAvailable, account]);

  const walletSignature = localStorage.getItem("signature");

  useEffect(() => {
    if (account && signature && walletSignature) {
      dispatch(checkUserInfoAvailability(`walletAddress=${account}`));
      dispatch(
        signWalletSuccess({
          signature: signature?.result || walletSignature,
          msg: "signed successfully",
        })
      );
      setIsWalletSigned(true);
    }
  }, [account, walletSignature, signature]);

  useEffect(() => {
    if (account) {
      dispatch(walletLoginStart());
      try {
        dispatch(
          walletLoginSuccess({
            walletAddress: account,
            msg: "Wallet connected successfully",
          })
        );
      } catch (e) {
        dispatch(walletLoginFail({ msg: e }));
      }
    }
  }, [account, dispatch]);

  return (
    <>
      <div className="wallet-wrapper">
        <div className="wallet-brand">
          <img src={logo} alt="logo" className="login___logo__img" />
        </div>
        <div className="wallet-card">
          <p className="wallet-card-heading">Connect Your Wallet</p>
          <div className="filters">
            {connections.map((connection) => (
              <Option
                key={connection.getName()}
                connection={connection}
                setType={setConnectedWallet}
                type={connectedWallet}
                signedMessage={signedMessage}
                setSignature={(sign) => {
                  setSignature(sign);
                  setIsWalletSigned(true);
                }}
                setSignedMessage={setSignedMessage}
                // setProfileOpen={setProfileOpen}
                menuClose={menuClose}
                setError={setError}
                signature={signature}
                className="wallet-button"
              />
            ))}
          </div>
          <div>
            <Link className="faq-link" to="/faq">
              FAQ
            </Link>
          </div>
        </div>

        {/* <div className="option-list">
          <div className="mt-4 filters mendatory_sign fw-300 fs-11 text-uppercase text-danger">
            {error ? error.message : null}
          </div>
        </div> */}
        {shouldRegister && (
          <SignupRedirectModal
            payload={{
              title: `Registration required`,
            }}
            openClose={shouldRegister}
            handleOpenClose={modalHandler}
          />
        )}
        {isEmailResend && (
          <ResendMailModal
            payload={{
              title: "Didn't receive verification link?",
            }}
            handleResendEmail={handleResendEmail}
            openClose={isEmailResend}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  // const { error } = state.login;
  return { state };
};

export default withRouter(connect(mapStateToProps, null)(Login));
