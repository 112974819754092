import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import Web3 from "web3";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import {
  commonSaga,
  fetchIcoInfo,
  getGasFeeSaga,
  getProofSaga,
  updateTokenCount,
} from "../../store/actions";
import CoinPaymentForm from "./CoinPaymentForm";
import ConfirmModal from "../../components/Modal/ConfirmModal";
import AvailabilityDetails from "../AvailabilityDetails/AvailabilityDetails";
import useAutoHeadsICOAndVestingContract from "../../hooks/useAutoHeadsICOAndVesting";
import useAutoHeadsTokenContract from "../../hooks/useAutoHeadsTokenContract";
import MoonPayWidget from "./MoonPayWidget";
import TransactionSuccess from "../../components/Modal/TransactionSuccess";
import { formatDecimalValue, marks } from "../../utils";
import PrettoSlider from "../../components/slider/Slider";
import getWalletBalance from "../../utils/getWalletBalance";

import "./BuyCoin.css";

const toastSettings = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const BuyCoin = ({ setTransLoader, transLoader }) => {
  const dispatch = useDispatch();
  const autoHeadsICOAndVestingContract = useAutoHeadsICOAndVestingContract();
  const autoHeadsTokenContract = useAutoHeadsTokenContract();
  const { provider, account } = useWeb3React();

  const { userData } = useSelector((state) => state.auth);
  const { info, proofData, gasFeeLoader, dollarRate } = useSelector(
    (state) => state.ico
  );

  const [guValue, setguValue] = useState("");
  const [totalEthToSend, setTotalEthToSend] = useState(0);
  const [totalUsdtoSend, setTotalUsdtoSend] = useState("");
  const [isToken, setIsToken] = useState("WT");
  const [confirmModal, setConfirmModal] = useState(false);
  const [isBuyCoinLoading, setIsBuyCoinLoading] = useState(false);
  const [rate, setRate] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("POL");
  const [visible, setVisible] = useState(false);
  const [ahtBalance, setAthdBalance] = useState("");
  const [showTxnModal, setShowTxnModal] = useState("");
  const [tokenInWei, setTokenInWei] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [estimatedGasFee, setEstimatedGasFee] = useState(0);

  const fetchGasFee = async () => {
    try {
      const CONTRACTINSTANCE = await autoHeadsICOAndVestingContract;

      const gasAmount = await CONTRACTINSTANCE.estimateGas.buyTokens(
        proofData?.items,
        {
          from: userData?.walletAddress,
          value: Web3.utils.toWei("1").toString(),
        }
      );
      const web3 = new Web3(
        `https://polygon-amoy.g.alchemy.com/v2/RClkjHJ4zED8c-HUKW1CSNOL34aCx6V1`
      );
      const gasPrice = await web3.eth.getGasPrice();
      const totalGasPrice = gasAmount * gasPrice;
      console.log(gasAmount.toString(), gasPrice.toString());
      const totalGasPriceEth = web3.utils.fromWei(
        totalGasPrice.toString(),
        "ether"
      );
      console.log(totalGasPriceEth);
      setEstimatedGasFee(totalGasPriceEth);
    } catch (error) {
      console.error("Error fetching gas fee:", error);
      return null;
    }
  };

  const resetInputFields = () => {
    setguValue("");
    setTotalEthToSend(0);
    setTotalUsdtoSend(0);
  };

  const handleChange = (value) => {
    const inputValue = Number(value);
    setVisible(false);
    const tokenRate = rate || info?.Ico?.rate;
    if (isToken === "WT") {
      setguValue(inputValue);
      const calculateEthAmount = inputValue / tokenRate;
      setTotalEthToSend(calculateEthAmount.toFixed(18));
      const calculateUSDAmount =
        inputValue * info?.Ico?.oneAthdToDollarExpected;
      setTotalUsdtoSend(calculateUSDAmount);
    } else if (isToken === "USD") {
      setTotalUsdtoSend(inputValue);
      const calculateEthAmount = inputValue / dollarRate;
      setTotalEthToSend(calculateEthAmount.toFixed(9));
      const calculateTotalToken =
        inputValue / info?.Ico?.oneAthdToDollarExpected;
      setguValue(calculateTotalToken);
    } else {
      setTotalEthToSend(inputValue);
      const calculateAHTAmount = inputValue * tokenRate;
      setguValue(calculateAHTAmount.toFixed(18));
      const calculateUSDAmount =
        calculateAHTAmount * info?.Ico?.oneAthdToDollarExpected;
      setTotalUsdtoSend(calculateUSDAmount);
    }
  };

  const handleConfirmModal = () => {
    if (isBuyCoinLoading) {
      setIsBuyCoinLoading(false);
    }
    setConfirmModal(!confirmModal);
  };

  const updateToken = () => {
    dispatch(
      updateTokenCount({
        newlyAddedToken: parseFloat(guValue),
      })
    );
  };

  const fetchAHTBalance = async () => {
    try {
      const contract = await autoHeadsTokenContract;
      const tokenInWei = await contract?.balanceOf(userData?.walletAddress);
      const ahtToken = tokenInWei / Math.pow(10, 18);
      setAthdBalance(ahtToken);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  // const checkAthdToken = async () => {
  //   const ethInWei = Web3.utils.toWei(totalEthToSend.toString());
  //   try {
  //     const contract = await autoHeadsICOAndVestingContract;
  //     let weiAmount = await contract?.getTokenAmount(ethInWei);
  //     setTokenInWei(weiAmount);
  //   } catch (err) {
  //     console.error(err);
  //     throw err;
  //   }
  // };

  const buyTokens = async () => {
    setTransLoader(true);

    let weiAmount = Web3.utils.toWei(totalEthToSend);

    try {
      const CONTRACTINSTANCE = await autoHeadsICOAndVestingContract;
      const transaction = await CONTRACTINSTANCE.buyTokens(proofData?.items, {
        from: userData?.walletAddress,
        value: weiAmount.toString(),
      });

      const transactionHash = transaction.hash;
      await transaction.wait();

      updateToken();

      const txnData = {
        transactionHash: transactionHash,
        token: guValue,
      };
      dispatch(
        commonSaga({
          endPoint: "/ico/save-transaction",
          method: "post",
          stateObj: "buyCoinTxn",
          payloadData: txnData,
        })
      );

      toast.success(`Transaction successfully completed`, toastSettings);

      setConfirmModal(false);

      dispatch(fetchIcoInfo());

      resetInputFields();
      await fetchAHTBalance();
    } catch (err) {
      console.error(err);
      if (err.message && err.code === 4001) {
        toast.error("Transaction has been declined", toastSettings);
      } else if (err?.message?.includes("insufficient funds")) {
        toast.error(
          "Insufficient funds for intrinsic transaction cost",
          toastSettings
        );
      } else {
        toast.error("Transaction unsuccessful!", toastSettings);
      }
    } finally {
      setTransLoader(false);
      handleConfirmModal();
    }
  };

  const openMoonpayWidget = (gasprice) => {
    const totalETh = parseFloat(gasprice) / Math.pow(10, 18);
    const gasfeeInDollar = totalETh * dollarRate + parseFloat(totalUsdtoSend);
    setTotalUsdtoSend(gasfeeInDollar.toFixed(9));
    setVisible(!visible);
  };

  const buyCoinHandler = async () => {
    if (paymentMethod === "USD") {
      const data = {
        tokenAmount: guValue,
      };
      dispatch(getGasFeeSaga({ data, openMoonpayWidget }));
    } else {
      setIsBuyCoinLoading(true);
      handleConfirmModal();
    }
  };

  const fetchICOData = async () => {
    try {
      const contract = await autoHeadsICOAndVestingContract;
      const oneethuat = await contract?.getICORoundDetails();
      setRate(Number(oneethuat?.rate?._hex));
    } catch (err) {
      console.error(err);
    }
  };

  const handleTxnModal = () => {
    setShowTxnModal(!showTxnModal);
  };

  const handleBuyTokenUsingFiat = useCallback(async () => {
    updateToken();
    setShowTxnModal(true);
    dispatch(fetchIcoInfo());
    resetInputFields();
    setVisible(false);
    fetchAHTBalance();
  }, []);

  const getValueBasedOnToken = (token) => {
    let value;

    if (token === "WT") {
      value = guValue;
    } else if (token === "USD") {
      value = totalUsdtoSend;
    } else {
      value = totalEthToSend;
    }

    return value;
  };

  useEffect(() => {
    if (provider && account) {
      fetchICOData();
      fetchAHTBalance();
      fetchGasFee();
    }
  }, [provider, account]);

  useEffect(() => {
    if (provider && proofData) {
      fetchGasFee();
    }
  }, [provider, proofData]);

  useEffect(() => {
    if (!info) {
      dispatch(fetchIcoInfo());
    }
    dispatch(getProofSaga());
    setVisible(false);
  }, []);

  useEffect(() => {
    if (userData?.walletAddress) {
      getWalletBalance(userData?.walletAddress).then((balance) => {
        if (balance !== null) {
          setWalletBalance(balance);
        } else {
          toast.error("Failed to fetch wallet balance.");
        }
      });
    }
  }, [userData]);

  return (
    <React.Fragment>
      <Row className="h-100 gap-20">
        <Col lg={12}>
          <Card className="buy_coin_card m-0">
            <CardBody>
              <div className="d-flex justify-content-between">
                <CardTitle className="mb-4 buycoin__header">Buy Coin</CardTitle>
              </div>
              <div className="mb-2">
                <Label
                  className="form-label buycoin__lable__title"
                  htmlFor="formrow-email-input"
                >
                  {isToken === "WT"
                    ? " Enter value in WT"
                    : isToken === "USD"
                    ? " Enter value in USD"
                    : "Enter value in POL"}
                </Label>
                <div className="coin__payment__form ">
                  <div className="input-wrapper with-suffix d-flex">
                    <Input
                      type="text"
                      className="token_input"
                      placeholder={`${
                        isToken === "WT"
                          ? "0 WT"
                          : isToken === "USD"
                          ? "0 USD"
                          : "0 POL"
                      }`}
                      id="example-number-inputs-1"
                      value={getValueBasedOnToken(isToken)}
                      onChange={(event) => {
                        const value = event.target.value.replace(
                          /[^0-9.]/g,
                          ""
                        );
                        handleChange(value);
                      }}
                      autoComplete="off"
                      style={{
                        backgroundColor: "",
                        border: "0px",
                      }}
                    />

                    <select
                      className="select_mode"
                      value={isToken}
                      onChange={(e) => setIsToken(e.target.value)}
                    >
                      <option value="WT">WT</option>
                      <option value="POL">POL</option>
                      <option value="USD">USD</option>
                    </select>

                    <div className="suffix">
                      <span className="filler" id="filler">
                        {getValueBasedOnToken(isToken)}
                      </span>
                      <span>
                        {guValue && totalUsdtoSend && totalEthToSend
                          ? ` ${isToken}`
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="select-currency">
                    <div className="coin__cal">
                      <span
                        className="coin__pay__cal"
                        style={{ fontSize: "12px" }}
                      >
                        {isToken === "WT" ? "~ $" : ""}

                        {isToken === "USD" || isToken === "POL"
                          ? `${formatDecimalValue(guValue) || 0} WT`
                          : isToken === "WT"
                          ? `${formatDecimalValue(totalUsdtoSend) || 0} USD`
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                {paymentMethod === "POL" && (
                  <>
                    <div className="slider-body">
                      <span>Wallet Balance : </span>
                      {walletBalance} POL
                    </div>
                    <PrettoSlider
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      defaultValue={0}
                      onChange={(e) => {
                        setIsToken("POL");
                        const etherAmount = e.target.value;
                        if (etherAmount >= 0) {
                          const value = walletBalance * (etherAmount / 100);
                          const subtractGasFee = etherAmount
                            ? value - (Number(estimatedGasFee) + 0.000003)
                            : 0;
                          handleChange(subtractGasFee);
                        }
                      }}
                      step={25}
                      marks={marks}
                      min={0}
                      max={100}
                      valueLabelFormat={(value) => `${value}%`}
                    />
                  </>
                )}
              </div>

              <CoinPaymentForm
                TotalEthToSend={totalEthToSend}
                TotalUSD={totalUsdtoSend || 0}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                setVisible={setVisible}
              />
              <Col>
                <Button
                  type="submit"
                  className={`w-lg mt-3 w-100  primary_btn`}
                  onClick={buyCoinHandler}
                  disabled={!guValue}
                >
                  {isBuyCoinLoading || gasFeeLoader ? (
                    <>
                      {" "}
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {"    "} <span>Waiting for confirmation...</span>
                    </>
                  ) : (
                    "Buy Now"
                  )}
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="h-100 m-0">
            <CardBody>
              <AvailabilityDetails
                ahtBalance={ahtBalance}
                walletBalance={walletBalance}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {confirmModal && (
        <ConfirmModal
          openClose={confirmModal}
          handleOpenClose={handleConfirmModal}
          guValue={totalEthToSend}
          payload={{
            title: "Confirm Payment",
            body: `A total of  ${guValue} WT token will be allocated towards the wallet address ${userData.walletAddress}. It will be transferred only after successful completion of the ICO.`,
          }}
          onSubmit={buyTokens}
          transLoader={transLoader}
          setTransLoader={setTransLoader}
        />
      )}
      <MoonPayWidget
        visible={visible}
        totalAmount={parseFloat(totalUsdtoSend)}
        handleBuyTokenUsingFiat={handleBuyTokenUsingFiat}
        tokenAmount={guValue}
      />
      <TransactionSuccess open={showTxnModal} handleTxnModal={handleTxnModal} />
    </React.Fragment>
  );
};

export default BuyCoin;
