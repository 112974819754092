import * as actionLabels from "../../actionLabels";

export const initialState = {
  isLoading: false,
  otpData: null,
  authToken: "",
  errorMsg: "",

  profileData: null,
};

const profile = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.GET_PROFILE_START:
      return { ...state, isLoading: false };
    case actionLabels.GET_PROFILE_SUCCESS: {
      return {
        ...state,

        profileData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.GET_PROFILE_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    case actionLabels.UPDATE_NUMBER_START:
      return { ...state, isLoading: false };
    case actionLabels.UPDATE_NUMBER_SUCCESS: {
      return {
        ...state,

        // profileData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.UPDATE_NUMBER_FAIL: {
      return { ...state, isLoading: false, errorMsgAp: payload };
    }

    default:
      return state;
  }
};

export default profile;
