import * as actionLabels from "../../actionLabels";

export const initialState = {
  isLoading: false,
  adminData: null,
  errorMsg: "",
  activityLogsData: null,
  viewAdminData: null,
};

const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.GET_ALL_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.GET_ALL_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.GET_ALL_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.ADD_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.ADD_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.ADD_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.DELETE_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.DELETE_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.EDIT_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.EDIT_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        adminData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.EDIT_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.ACTIVITY_LOGS_START:
      return { ...state, isLoading: true };

    case actionLabels.ACTIVITY_LOGS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activityLogsData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.ACTIVITY_LOGS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    case actionLabels.VIEW_ADMIN_START:
      return { ...state, isLoading: true };

    case actionLabels.VIEW_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        viewAdminData: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.VIEW_ADMIN_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }
    
    case actionLabels.COMMON_START:
      return {
        ...state,
        [payload.stateObj]: {
            isLoading: true
        }
      };   

    case actionLabels.COMMON_SUCCESS: {
      return {
        ...state,
        [payload.stateObj]: {
            isLoading: false,      
            errorMsg: '',
            ...payload
        }
      };
    }

    case actionLabels.COMMON_FAIL:
      return {
        ...state,
        [payload.stateObj]: {
            isLoading: false,
            ...payload
        }
    }; 

    default:
      return state;
  }
};

export default adminReducer;
