const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
if (typeof INFURA_KEY === "undefined") {
  throw new Error(
    `REACT_APP_INFURA_KEY must be a defined environment variable`
  );
}
const QUICKNODE_MAINNET_RPC_URL =
  process.env.REACT_APP_QUICKNODE_MAINNET_RPC_URL;
// if (typeof QUICKNODE_MAINNET_RPC_URL === "undefined") {
//   throw new Error(
//     `REACT_APP_QUICKNODE_MAINNET_RPC_URL must be a defined environment variable`
//   );
// }
// const QUICKNODE_BNB_RPC_URL = process.env.REACT_APP_BNB_RPC_URL;
// if (typeof QUICKNODE_BNB_RPC_URL === "undefined") {
//   throw new Error(
//     `REACT_APP_BNB_RPC_URL must be a defined environment variable`
//   );
// }

const MAINNET = 1;
const POLYGON = 137;
const POLYGON_AMOY = 80002;
const SEPOLIA = 11155111;
const BNB = 56;
const HARDHAT = 31337;

export const FALLBACK_URLS = {
  [MAINNET]: [
    // "Safe" URLs
    "https://api.mycryptoapi.com/eth",
    "https://cloudflare-eth.com",
    // "Fallback" URLs
    "https://rpc.ankr.com/eth",
    "https://eth-mainnet.public.blastapi.io",
  ],
  [HARDHAT]: [
    // "Safe" URLs
    // "https://api.mycryptoapi.com/eth",
    // "https://cloudflare-eth.com",
    // "Fallback" URLs
    // "https://rpc.ankr.com/eth",
    // "https://eth-mainnet.public.blastapi.io",
  ],
  [POLYGON]: [
    // "Safe" URLs
    "https://polygon-rpc.com/",
    "https://rpc-mainnet.matic.network",
    "https://matic-mainnet.chainstacklabs.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
    "https://matic-mainnet-full-rpc.bwarelabs.com",
  ],
  [POLYGON_AMOY]: [
    // "Safe" URLs
    "https://polygon-amoy.g.alchemy.com/v2/RClkjHJ4zED8c-HUKW1CSNOL34aCx6V1",
  ],
  [SEPOLIA]: [
    "https://sepolia.infura.io/v3/",
    "https://rpc.sepolia.org",
    "https://ethereum-sepolia.publicnode.com",
  ],
  [BNB]: [
    // "Safe" URLs
    "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
    "https://bsc-mainnet.gateway.pokt.network/v1/lb/6136201a7bad1500343e248d",
    "https://1rpc.io/bnb",
    "https://bsc-dataseed3.binance.org",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed1.ninicoin.io",
    "https://binance.nodereal.io",
    "https://bsc-dataseed4.defibit.io",
    "https://rpc.ankr.com/bsc",
  ],
};
export const RPC_URLS = {
  [MAINNET]: [
    `https://mainnet.infura.io/v3/${INFURA_KEY}`,
    QUICKNODE_MAINNET_RPC_URL,
    ...FALLBACK_URLS[MAINNET],
  ],
  [HARDHAT]: [`http://44.198.2.132:8545`, ...FALLBACK_URLS[HARDHAT]],
  [POLYGON]: [
    `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[POLYGON],
  ],
  [SEPOLIA]: [
    `https://sepolia.infura.io/v3/${INFURA_KEY}`,
    ...FALLBACK_URLS[SEPOLIA],
  ],
  [POLYGON_AMOY]: [
    "https://polygon-amoy.g.alchemy.com/v2/RClkjHJ4zED8c-HUKW1CSNOL34aCx6V1",
  ],
  // [BNB]: [QUICKNODE_BNB_RPC_URL, ...FALLBACK_URLS[BNB]],
};
