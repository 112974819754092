/* eslint-disable no-unused-vars */
import axios from "axios";
import { put, call, takeEvery } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";

import { errorHandler } from "../../../utils";
import {
  fetchIcoInfoStart,
  fetchIcoInfoSuccess,
  fetchIcoInfoFail,
  postTransHashStart,
  postTransHashSuccess,
  postTransHashFail,
  fetchTransactionsStart,
  fetchTransactionsSuccess,
  fetchTransactionsFail,
  recordPublicSaleStart,
  recordPublicSaleSuccess,
  recordPublicSaleFail,
  recordClaimTokenSuccess,
  recordClaimTokenStart,
  recordClaimTokenFail,
  updateTokenCountSuccess,
  getProofStart,
  getProofSuccess,
  getProofFail,
  getGasFeeStart,
  getGasFeeSuccess,
  getGasFeeFail,
  getTxnDetailsStart,
  getTxnDetailsSuccess,
  getTxnDetailsFail,
} from "../../actions";

// function countdown(secs) {
//   return eventChannel((emitter) => {
//     const iv = setInterval(() => {
//       secs -= 1;
//       if (secs > 0) {
//         emitter(secs);
//       } else {
//         // this causes the channel to close
//         emitter(END);
//       }
//     }, 10000);
//     // The subscriber must return an unsubscribe function
//     return () => {
//       clearInterval(iv);
//     };
//   });
// }

export function* fetchIcoInfo() {
  yield put(fetchIcoInfoStart());
  yield errorHandler({
    endpoint: "/ico",
    successHandler: yield function* (response) {
      // yield put(fetchIcoInfoSuccess(response.data));
      yield put(fetchIcoInfoSuccess(response.data));
    },
    failHandler: fetchIcoInfoFail,
    apiType: "get",
    token: localStorage.getItem("token"),
  });
}

// export function* fetchIcoInfoInInterval() {
//   const channel = yield call(countdown, 10);

//   yield takeEvery(channel, function* (secs) {
//     // Do your magic..
//     yield put(fetchIcoInfoStart());
//     yield errorHandler({
//       endpoint: "/ico",
//       successHandler: yield function* (response) {
//         // yield put(fetchIcoInfoSuccess(response.data));
//         yield put(fetchIcoInfoSuccess(response.data));
//       },
//       failHandler: fetchIcoInfoFail,
//       apiType: "get",
//     });
//   });
// }

export function* postTransHash({ payload, disconnectWallet }) {
  yield put(postTransHashStart());
  yield errorHandler({
    endpoint: "/ico/buy",
    successHandler: yield function* (response) {
      yield put(postTransHashSuccess(response.data));
      yield put(updateTokenCountSuccess(response.data));
    },
    failHandler: postTransHashFail,
    apiType: "post",
    token: localStorage.getItem("token"),
    payload,
    disconnectWallet,
  });
}

export function* recordPublicSale({ payload, disconnectWallet }) {
  yield put(recordPublicSaleStart());
  yield errorHandler({
    endpoint: "/ico/public-sale",
    successHandler: yield function* (response) {
      yield put(recordPublicSaleSuccess(response.data));
      yield put(updateTokenCountSuccess(response.data));
    },
    failHandler: recordPublicSaleFail,
    apiType: "post",
    token: localStorage.getItem("token"),
    payload,
    disconnectWallet,
  });
}

export function* fetchTransactions(action) {
  const {url=''} = action.payload
  yield put(fetchTransactionsStart());
  yield errorHandler({
    endpoint: url,
    successHandler: yield function* (response) {
      yield put(fetchTransactionsSuccess(response.data));
    },
    failHandler: fetchTransactionsFail,
    apiType: "get",
    token: localStorage.getItem("token"),
  });
}

export function* recordClaimToken({ payload, disconnectWallet }) {
  yield put(recordClaimTokenStart());
  yield errorHandler({
    endpoint: "/ico/tokens-claimed",
    successHandler: yield function* (response) {
      yield put(recordClaimTokenSuccess(response.data));
    },
    failHandler: recordClaimTokenFail,
    apiType: "post",
    token: localStorage.getItem("token"),
    payload,
    disconnectWallet,
  });
}

// export function* fetchTransactions({ payload }) {
//   yield put(fetchTransactionsStart());
//   try {
//     const response = yield call(
//       axios.get,
//       `https://api-testnet.polygonscan.com/api?module=account&action=txlist&address=${payload.walletAddress}&startblock=0&endblock=99999999&sort=desc&apikey=TT75V7WIHPH2X581UHN77EWH8JRUQYURC4`
//     );

//     if (response.data) {
//       yield put(fetchTransactionsSuccess(response.data));
//     }
//   } catch (e) {
//     fetchTransactionsFail(e);
//   }
// }

export function* getProofSaga() {
  yield put(getProofStart());
  yield errorHandler({
    endpoint: `/ico/proff`,
    successHandler: yield function* (response) {
      yield put(getProofSuccess(response.data));
    },
    failHandler: getProofFail,
    apiType: "get",
    token: true,
  });
}

export function* getGasFeeSaga(action) {
  const {data , openMoonpayWidget} = action.payload
  yield put(getGasFeeStart());
  yield errorHandler({
    endpoint: `/ico/gas-estimate`,
    successHandler: yield function* (response) {
      yield put(getGasFeeSuccess(response.data));
      openMoonpayWidget(response?.data?.items?.gasPrice)
    },
    failHandler: getGasFeeFail,
    apiType: "post",
    payload: data,
    token: true,
  });
}

export function* getTxnDetailsSaga(action) {
  const {id} = action.payload
  yield put(getTxnDetailsStart());
  yield errorHandler({
    endpoint: `transactions/details?vestingId=${id}`,
    successHandler: yield function* (response) {
      yield put(getTxnDetailsSuccess(response.data));
    },
    failHandler: getTxnDetailsFail,
    apiType: "get",
    token: true,
  });
}