import * as actionLabels from "../../actionLabels";

export const initialState = {
  walletAddress: "",
  isWalletConnected: false,
  signature: "",
  isSigned: false,
  isFirstLogin: false,
  userData: null,
  isEmailVerified: null,
  accessToken: "",
  isAuthenticated: false,
  msg: "",
  msgStatus: "",
  isLoading: false,
  walletAvailable: null,
  networkSwitch: false,
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.RESET_MESSAGE:
      return { ...state, msg: "", msgStatus: "" };
    case actionLabels.LOGIN_START:
      return { ...state, isLoading: true };

    case actionLabels.LOGIN_SUCCESS: {
      return {
        ...state,
        isFirstLogin: payload.isFirstLogin,
        accessToken: payload.token,
        isAuthenticated: true,
        msg: payload.message,
        msgStatus: "success",
        isLoading: false,
      };
    }
    case actionLabels.LOGIN_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload.message || payload,
        msgStatus: "fail",
        isEmailVerified: payload.data ? payload.data.isEmailVerified : null,
      };
    }
    case actionLabels.CHECK_USERINFO_AVAIL_START:
      return { ...state, isLoading: true };

    case actionLabels.CHECK_USERINFO_AVAIL_SUCCESS: {
      return {
        ...state,
        walletAvailable: payload.items[0].walletAddress,
        isLoading: false,
      };
    }
    case actionLabels.CHECK_USERINFO_AVAIL_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload.message,
        msgStatus: "fail",
      };
    }
    case actionLabels.FETCH_PROFILE_INFO_START:
      return { ...state, isLoading: true };

    case actionLabels.FETCH_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        userData: payload?.items,
        isLoading: false,
      };
    }
    case actionLabels.FETCH_PROFILE_INFO_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload.message,
        msgStatus: "fail",
      };
    }

    case actionLabels.WALLET_LOGIN_START:
      return { ...state, isLoading: true };

    case actionLabels.WALLET_LOGIN_SUCCESS: {
      return {
        ...state,
        walletAddress: payload.walletAddress,
        isWalletConnected: true,
        // msg: payload.msg,
        // msgStatus: "success",
        isLoading: false,
      };
    }
    case actionLabels.WALLET_LOGIN_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload.msg,
        msgStatus: "fail",
      };
    }

    case actionLabels.SIGN_WALLET_START:
      return {
        ...state,
        // , isLoading: true
      };

    case actionLabels.SIGN_WALLET_SUCCESS: {
      return {
        ...state,
        signature: payload.signature,
        isSigned: true,
        // isLoading: false,
      };
    }
    case actionLabels.SIGN_WALLET_FAIL: {
      return {
        ...state,
        // isLoading: false,
        // msg: payload.msg,
        // msgStatus: "fail",
      };
    }

    case actionLabels.SIGNUP_START:
      return { ...state, isLoading: true };

    case actionLabels.SIGNUP_SUCCESS: {
      return {
        ...state,
        msg: payload.message,
        msgStatus: "success",
        isLoading: false,
      };
    }
    case actionLabels.SIGNUP_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload,
        msgStatus: "fail",
      };
    }

    case actionLabels.RESEND_EMAIL_START:
      return { ...state, isLoading: false };

    case actionLabels.RESEND_EMAIL_SUCCESS: {
      return {
        ...state,
        msg: payload.message,
        msgStatus: "success",
        isLoading: false,
      };
    }
    case actionLabels.RESEND_EMAIL_FAIL: {
      // console.log(payload);
      return {
        ...state,
        isLoading: false,
        msg: payload,
        msgStatus: "fail",
      };
    }

    case actionLabels.EDIT_PROFILE_START:
      return { ...state, isLoading: true };

    case actionLabels.EDIT_PROFILE_SUCCESS: {
      return {
        ...state,       
        msg: payload.message,
        msgStatus: "success",
        isLoading: false,
      };
    }
    case actionLabels.EDIT_PROFILE_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload,
        msgStatus: "fail",
      };
    }

    case actionLabels.UPDATE_TOKEN_CLAIM_START:
      return { ...state, isLoading: true };

    case actionLabels.UPDATE_TOKEN_CLAIM_SUCCESS: {
      return {
        ...state,
        userData: payload.items[0],
        isLoading: false,
      };
    }
    case actionLabels.UPDATE_TOKEN_CLAIM_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actionLabels.UPDATE_TOKEN_COUNT_START:
      return { ...state, isLoading: true };

    case actionLabels.UPDATE_TOKEN_COUNT_SUCCESS: {
      return {
        ...state,
        userData: payload.items[0],
        isLoading: false,
      };
    }
    case actionLabels.UPDATE_TOKEN_COUNT_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case actionLabels.NETWORK_SWITCH_SUCCESS: {
      return {
        ...state,
        networkSwitch: payload,
      };
    }

    case actionLabels.UPATE_TOKEN_COUNT: {
      return {
        ...state,
        userData: {
          ...state.userData,
          tokenCount: state.userData.tokenCount + payload.newlyAddedToken,
        },
      };
    }

    case actionLabels.LOGOUT_START:
      return { ...state, isLoading: true };

    case actionLabels.LOGOUT_SUCCESS: {
      return {
        ...state,
        walletAddress: "",
        signature: "",
        accessToken: "",
        isWalletConnected: false,
        isSigned: false,
        msg: payload.msg,
        msgStatus: "success",
        isLoading: false,
        isAuthenticated: false,
        userData: null,
      };
    }
    case actionLabels.LOGOUT_FAIL: {
      return {
        ...state,
        isLoading: false,
        msg: payload.msg,
        msgStatus: "fail",
      };
    }

    default:
      return state;
  }
};

export default authReducer;

// case actionLabels.RESEND_OTP_START:
//   return { ...state, isLoading: false };
// case actionLabels.RESEND_OTP_SUCCESS: {
//   return {
//     ...state,
//     // isLoading: false,
//     // isLogin: true,
//     // isLoading: false,
//     // authToken: payload.token,
//     // fcmToken: payload.deviceToken,
//     otpData: payload,
//     errorMsg: "",
//   };
// }
// case actionLabels.RESEND_OTP_FAIL: {
//   return { ...state, isLoading: false, errorMsg: payload };
// }

// case actionLabels.ACTIVATE_PASSWORD_START:
//   return { ...state, isLoading: false };
// case actionLabels.ACTIVATE_PASSWORD_SUCCESS: {
//   return {
//     ...state,
//     // isLoading: false,
//     // isLogin: true,
//     // isLoading: false,
//     // authToken: payload.token,
//     // fcmToken: payload.deviceToken,
//     activatePassword: payload,
//     errorMsg: "",
//   };
// }
// case actionLabels.ACTIVATE_PASSWORD_FAIL: {
//   return { ...state, isLoading: false, errorMsgAp: payload };
// }

// case actionLabels.RESET_PASSWORD_START:
//   return { ...state, isLoading: false };
// case actionLabels.RESET_PASSWORD_SUCCESS: {
//   return {
//     ...state,
//     // isLoading: false,
//     // isLogin: true,
//     // isLoading: false,
//     // authToken: payload.token,
//     // fcmToken: payload.deviceToken,
//     // activatePassword: payload,
//     resetPassword: payload,
//     errorMsg: "",
//   };
// }
// case actionLabels.RESET_PASSWORD_FAIL: {
//   return { ...state, isLoading: false, errorMsgAp: payload };
// }
