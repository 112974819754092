import * as actionLabels from "../../actionLabels";

export const fetchIcoInfoStart = () => ({
  type: actionLabels.FETCH_ICO_INFO_START,
});

export const fetchIcoInfo = () => ({
  type: actionLabels.FETCH_ICO_INFO_SAGA,
});

export const fetchIcoInfoSuccess = (payload) => ({
  type: actionLabels.FETCH_ICO_INFO_SUCCESS,
  payload,
});

export const fetchIcoInfoFail = (payload) => ({
  type: actionLabels.FETCH_ICO_INFO_FAIL,
  payload,
});

export const fetchIcoInfoInIntervalStart = () => ({
  type: actionLabels.FETCH_ICO_INFO_IN_INTERVAL_START,
});

export const fetchIcoInfoInInterval = () => ({
  type: actionLabels.FETCH_ICO_INFO_IN_INTERVAL_SAGA,
});

export const fetchIcoInfoInIntervalSuccess = (payload) => ({
  type: actionLabels.FETCH_ICO_INFO_IN_INTERVAL_SUCCESS,
  payload,
});

export const fetchIcoInfoInIntervalFail = (payload) => ({
  type: actionLabels.FETCH_ICO_INFO_IN_INTERVAL_FAIL,
  payload,
});

export const postTransHashStart = () => ({
  type: actionLabels.POST_TRANS_HASH_START,
});

export const postTransHash = (payload, disconnectWallet) => ({
  type: actionLabels.POST_TRANS_HASH_SAGA,
  payload,
  disconnectWallet,
});

export const postTransHashSuccess = (payload) => ({
  type: actionLabels.POST_TRANS_HASH_SUCCESS,
  payload,
});

export const postTransHashFail = (payload) => ({
  type: actionLabels.POST_TRANS_HASH_FAIL,
  payload,
});

export const recordPublicSaleStart = () => ({
  type: actionLabels.RECORD_PUBLIC_SALE_START,
});

export const recordPublicSale = (payload, disconnectWallet) => ({
  type: actionLabels.RECORD_PUBLIC_SALE_SAGA,
  payload,
  disconnectWallet,
});

export const recordPublicSaleSuccess = (payload) => ({
  type: actionLabels.RECORD_PUBLIC_SALE_SUCCESS,
  payload,
});

export const recordPublicSaleFail = (payload) => ({
  type: actionLabels.RECORD_PUBLIC_SALE_FAIL,
  payload,
});

export const fetchTransactionsStart = () => ({
  type: actionLabels.FETCH_TRANSACTIONS_START,
});

export const fetchTransactions = (payload) => ({
  type: actionLabels.FETCH_TRANSACTIONS_SAGA,
  payload,
});

export const fetchTransactionsSuccess = (payload) => ({
  type: actionLabels.FETCH_TRANSACTIONS_SUCCESS,
  payload,
});

export const fetchTransactionsFail = (payload) => ({
  type: actionLabels.FETCH_TRANSACTIONS_FAIL,
  payload,
});

export const recordClaimTokenStart = () => ({
  type: actionLabels.RECORD_CLAIM_TOKEN_START,
});

export const recordClaimToken = (payload, disconnectWallet) => ({
  type: actionLabels.RECORD_CLAIM_TOKEN_SAGA,
  payload,
  disconnectWallet,
});

export const recordClaimTokenSuccess = (payload) => ({
  type: actionLabels.RECORD_CLAIM_TOKEN_SUCCESS,
  payload,
});

export const recordClaimTokenFail = (payload) => ({
  type: actionLabels.RECORD_CLAIM_TOKEN_FAIL,
  payload,
});

export const getProofStart = () => ({
  type: actionLabels.GET_PROOF_START,
});

export const getProofSaga = (payload) => ({
  type: actionLabels.GET_PROOF_SAGA,
  payload
});

export const getProofSuccess = (payload) => ({
  type: actionLabels.GET_PROOF_SUCCESS,
  payload,
});

export const getProofFail = (payload) => ({
  type: actionLabels.GET_PROOF_FAIL,
  payload,
});

export const getGasFeeStart = () => ({
  type: actionLabels.GET_GAS_FEE_START,
});

export const getGasFeeSaga = (payload) => ({
  type: actionLabels.GET_GAS_FEE_SAGA,
  payload
});

export const getGasFeeSuccess = (payload) => ({
  type: actionLabels.GET_GAS_FEE_SUCCESS,
  payload,
});

export const getGasFeeFail = (payload) => ({
  type: actionLabels.GET_GAS_FEE_FAIL,
  payload,
});

export const setDollarRate = (payload) => ({
  type: actionLabels.SET_DOLLAR_RATE,
  payload,
});

export const getTxnDetailsSaga = (payload) => ({
  type: actionLabels.GET_TXN_DETAILS_SAGA,
  payload
});

export const getTxnDetailsSuccess = (payload) => ({
  type: actionLabels.GET_TXN_DETAILS_SUCCESS,
  payload,
});

export const getTxnDetailsFail = (payload) => ({
  type: actionLabels.GET_TXN_DETAILS_FAIL,
  payload,
});

export const getTxnDetailsStart = (payload) => ({
  type: actionLabels.GET_TXN_DETAILS_START,
  payload,
});
