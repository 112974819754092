import React from "react";
import { useSyncExternalStore } from "react";
import { Web3ReactHooks, initializeConnector } from "@web3-react/core";
import { Actions } from "@web3-react/types";
import { WalletConnectV2 } from "./WalletConnectV2";
import { ChainId } from "@uniswap/sdk-core";
import WalletConnectIcon from "../assets/images/wallet-Connect.png";

export const ConnectionType = {
  INJECTED: "INJECTED",
  COINBASE_WALLET: "COINBASE_WALLET",
  WALLET_CONNECT_V2: "WALLET_CONNECT_V2",
};

function onError(error) {
  console.debug(`web3-react error: ${error}`);
}

export const walletConnectV2Connection = new (class {
  constructor() {
    this.initializer = (actions, defaultChainId = 80002) =>
      new WalletConnectV2({ actions, defaultChainId, onError });

    this.type = ConnectionType.WALLET_CONNECT_V2;
    this.getName = () => "WalletConnect";
    this.getIcon = () => WalletConnectIcon;
    this.shouldDisplay = () => true;

    this.activeConnector = initializeConnector(this.initializer);
    this.proxyConnector = new Proxy(
      {},
      {
        get: (_target, p, receiver) =>
          Reflect.get(this.activeConnector[0], p, receiver),
        getOwnPropertyDescriptor: (_target, p) =>
          Reflect.getOwnPropertyDescriptor(this.activeConnector[0], p),
        getPrototypeOf: () => WalletConnectV2.prototype,
        set: (_target, p, receiver) =>
          Reflect.set(this.activeConnector[0], p, receiver),
      }
    );
    this.proxyHooks = new Proxy(
      {},
      {
        get: (_target, p, receiver) => {
          return () => {
            const hooks = useSyncExternalStore(
              (onChange) => {
                this.onActivate = onChange;
                return () => (this.onActivate = undefined);
              },
              () => this.activeConnector[1]
            );
            return Reflect.get(hooks, p, receiver)();
          };
        },
      }
    );

    this.onActivate = undefined;
  }

  overrideActivate = (chainId) => {
    this.activeConnector = initializeConnector((actions) =>
      this.initializer(actions, chainId)
    );
    this.onActivate?.();
    return false;
  };

  get connector() {
    return this.proxyConnector;
  }
  get hooks() {
    return this.proxyHooks;
  }
})();
