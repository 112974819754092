import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import ReferralRecord from "./ReferralRecord";
import ClaimHistoryRecord from "./ClaimHistoryRecord";
import {
  claimReferralAction,
  getClaimHistoryListAction,
  getReferralListAction,
} from "../../store/actions";

const ReferralRewards = () => {
  const dispatch = useDispatch();
  const [activeTabJustify, setactiveTabJustify] = useState("1");
  const { userData } = useSelector((state) => state?.auth);
  const { referralList } = useSelector((state) => state?.referral);

  useEffect(() => {
    if (activeTabJustify === "1") {
      //api for referral
      dispatch(getReferralListAction());
    }
    if (activeTabJustify === "2") {
      //api for claim history
      dispatch(getClaimHistoryListAction());
    }
  }, [activeTabJustify, dispatch]);

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab);
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 section_header">
            Rewards
            <h4 className="mt-2">{userData?.rewardAmount ?? 0} USDT</h4>
            <Button
              className="primary_btn claim_btn"
              onClick={() => dispatch(claimReferralAction())}
              disabled={!referralList?.items?.length}
            >
              Claim
            </Button>
          </CardTitle>
          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#012611" }}
                className={classnames({
                  active: activeTabJustify === "1",
                })}
                onClick={() => {
                  toggleCustomJustified("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Referral</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#012611" }}
                className={classnames({
                  active: activeTabJustify === "2",
                })}
                onClick={() => {
                  toggleCustomJustified("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">History</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTabJustify}>
            <TabPane tabId="1" className="">
              <ReferralRecord />
            </TabPane>
            <TabPane tabId="2" className="">
              <ClaimHistoryRecord />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

export default ReferralRewards;
