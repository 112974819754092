import * as actionLabels from "../../actionLabels";

export const initialState = {
  isLoading: false,
  msg: "",
  msgStatus: "",
};

const helpCenterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SUBMIT_FEEDBACK_START:
      state = { ...state, isLoading: true };
      break;
    case actionLabels.SUBMIT_FEEDBACK_SUCCESS:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "success",
        isLoading: false,
      };
      break;
    case actionLabels.SUBMIT_FEEDBACK_FAIL:
      state = {
        ...state,
        msg: payload.message,
        msgStatus: "fail",
        isLoading: false,
      };
      break;
    case actionLabels.RESET_MESSAGE_FEEDBACK:
      return { ...state, msg: "", msgStatus: "" };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default helpCenterReducer;
