/* eslint-disable no-unused-vars */
import { put } from "redux-saga/effects";

import { errorHandler } from "../../../utils";
import {
  submitFeedbackStart,
  submitFeedbackSuccess,
  submitFeedbackFail,
} from "../../actions";

export function* submitFeedbackSaga({ payload, disconnectWallet }) {
  yield put(submitFeedbackStart());

  yield errorHandler({
    endpoint: "/help-center/contact",
    successHandler: yield function* (response) {
      yield put(submitFeedbackSuccess(response.data));
    },
    failHandler: submitFeedbackFail,
    apiType: "post",
    payload: payload,
    disconnectWallet,
    token: localStorage.getItem("token"),
  });
}
