import Web3 from "web3";

const getWalletBalance = async (walletAddress) => {
  try {
    // Connect to the Ethereum node
    const web3 = new Web3(
      `https://polygon-amoy.g.alchemy.com/v2/RClkjHJ4zED8c-HUKW1CSNOL34aCx6V1`
    );

    // Get the balance in Wei
    const balanceWei = await web3.eth.getBalance(walletAddress);

    // Convert Wei to Ether
    const balanceEther = web3.utils.fromWei(balanceWei, "ether");

    return balanceEther;
  } catch (error) {
    console.error("Error fetching wallet balance:", error);
    return null;
  }
};

export default getWalletBalance;
