import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import getWeb3 from "../utils/getWeb3";
// import { useWallet } from "use-wallet";

const useUserAccount = () => {
  const [account, setAccount] = useState(null);
  const { account: metamaskAccount, active, error } = useWeb3React();
  const fortmaticWallet = window.sessionStorage.getItem("fortmaticWallet");
  const connectedWallet = localStorage.getItem("connectedWallet");

  const fetchAccount = async () => {
    try {
      const web3 = await getWeb3();
      if (connectedWallet === "fortmatic") {
        const accounts = await web3?.eth?.getAccounts();
        setAccount(accounts[0]);
      } else if (
        connectedWallet === "metamask" ||
        connectedWallet === "walletConnect" ||
        connectedWallet === "coinbase"
      ) {
        const accounts = await web3?.eth?.getAccounts();
        setAccount(accounts[0]);
        // setAccount(metamaskAccount);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchAccount();
  }, [active, error, metamaskAccount, fortmaticWallet, connectedWallet]);

  return { account };
};

export default useUserAccount;
