import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import "./ModelUi.css";

import { resendEmailSaga } from "../../store/actions";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { useNavigate } from "react-router-dom";
const ResendMailModal = ({ payload, openClose, handleResendEmail }) => {
  const { title } = payload;
  const [email, setEmail] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (count !== 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
  }, [count]);

  const resetCount = () => {
    setCount(30);
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop() {
    handleResendEmail();
    removeBodyCss();
    if (window?.location?.pathname === "/signup") {
      navigate("/login");
    }
  }
  function onSubmit() {
    if (email) {
      dispatch(resendEmailSaga({ email }));
      resetCount();
    }

    // dispatch(hideResendEmailModal());
  }
  return (
    <>
      <Modal
        isOpen={openClose}
        toggle={() => {
          tog_backdrop();
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {title}
          </h5>

          <button
            type="button"
            className="btn-close icon-button"
            onClick={() => {
              tog_backdrop();
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <AvForm>
            <AvField
              name="email"
              label="Please enter your registered email address and click on resend to receive the verification link on your email."
              id="YourName"
              type="email"
              errorMessage="Invalid Email"
              validate={{
                required: { value: true },
                email: { value: true },
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </AvForm>
          {count ? <div className="mt-4">Try again in: {count}</div> : ""}
        </div>
        {/* <input
            type="email"
            className="form-control"
            placeholder="Enter registered email-id"
            value={email}
           
          /> */}

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light button is-ghost"
            onClick={() => {
              tog_backdrop();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-light button is-primary"
            onClick={() => {
              onSubmit(email);
            }}
            disabled={count ? true : false}
          >
            Resend
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ResendMailModal;
