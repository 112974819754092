import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "@ethersproject/contracts";

function getProviderOrSigner(provider, account) {
  return account ? provider.getSigner(account).connectUnchecked() : provider;
}

const useContract = (address = undefined, ABI) => {
  const { provider, account } = useWeb3React();
  return useMemo(async () => {
    if (!address || !ABI || !provider) return null;
    try {
      const contractInstance = new Contract(
        address,
        ABI,
        getProviderOrSigner(provider, account)
      );
      return contractInstance;
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [ABI, address, provider, account]);
};

export default useContract;
