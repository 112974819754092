import { useSyncExternalStore } from "react";
import { getConnection } from "../utils/connectors";
import { deletePersistedConnectionMeta, getPersistedConnectionMeta } from "../utils/meta";

let connectionReady = true;

export function useConnectionReady() {
  return useSyncExternalStore(
    (onStoreChange) => {
      if (connectionReady instanceof Promise) {
        connectionReady.finally(onStoreChange);
      }
      return () => undefined;
    },
    () => connectionReady === true
  );
}

async function connect(connector, type) {
  performance.mark(`web3:connect:${type}:start`);
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate();
    }
    return true;
  } catch (error) {
    console.debug(`web3-react eager connection error: ${error}`);
    return false;
  } finally {
    performance.measure(`web3:connect:${type}`, `web3:connect:${type}:start`);
  }
}

const meta = getPersistedConnectionMeta();
if (meta) {
  const selectedConnection = getConnection(meta);
  if (selectedConnection) {
    connectionReady = connect(selectedConnection.connector, meta)
      .then((connected) => {
        // if (!connected) console.log({ connected });
      })
      .catch((error) => {
        // Clear the persisted wallet type if it failed to connect.
        deletePersistedConnectionMeta();
        // Log it if it threw an unknown error.
        console.log(error);
      })
      .finally(() => {
        connectionReady = true;
      });
  }
}
