import { all, takeLatest, takeEvery } from "redux-saga/effects";
import * as actionLabels from "../actionLabels";
import {
  activityLogsSaga,
  addAdminSaga,
  commonSaga,
  deleteAdminSaga,
  editAdminSaga,
  getAllAdminSaga,
  viewAdminSaga,
} from "./admin/admin";
import {
  loginSaga,
  signupSaga,
  resendEmailSaga,
  editProfileSaga,
  fetchProfileInfo,
  checkUserInfoAvailability,
  updateTokenClaimSaga,
  networkSwitchSaga,
  logoutSaga,
} from "./auth/auth";
import { dashboardStatsSaga } from "./dashboard/dashboard";
import {
  changeLayout,
  changeLayoutWidth,
  changeLeftSidebarTheme,
  changeLeftSidebarType,
  changeTopbarTheme,
  showRightSidebar,
} from "./layout/layout";
import { getProfileSaga, updateNumberSaga } from "./profile/profile";
import { getAllUsersSaga, viewUserSaga } from "./user/user";
import { submitFeedbackSaga } from "./help-center/helpCenter";
import {
  signWalletFail,
  signWalletStart,
  signWalletSuccess,
  walletLoginFail,
  walletLoginStart,
  walletLoginSuccess,
  updateTokenCountSuccess,
} from "../actions";
import {
  fetchIcoInfo,
  postTransHash,
  fetchTransactions,
  recordPublicSale,
  recordClaimToken,
  getProofSaga,
  getGasFeeSaga,
  getTxnDetailsSaga,
  // fetchIcoInfoInInterval,
} from "./ico/ico";
import {
  claimReferralSaga,
  getClaimHistoryListSaga,
  getReferralListSaga,
  inviteUserSaga,
} from "./referral/referral";

export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.LOGOUT_SAGA, logoutSaga),
    takeLatest(actionLabels.SIGNUP_SAGA, signupSaga),
    takeLatest(actionLabels.RESEND_EMAIL_SAGA, resendEmailSaga),
    takeLatest(actionLabels.SIGN_WALLET_START, signWalletStart),
    takeLatest(actionLabels.SIGN_WALLET_SUCCESS, signWalletSuccess),
    takeLatest(actionLabels.SIGN_WALLET_FAIL, signWalletFail),
    takeLatest(actionLabels.WALLET_LOGIN_START, walletLoginStart),
    takeLatest(actionLabels.WALLET_LOGIN_SUCCESS, walletLoginSuccess),
    takeLatest(
      actionLabels.UPDATE_TOKEN_COUNT_SUCCESS,
      updateTokenCountSuccess
    ),
    takeLatest(actionLabels.WALLET_LOGIN_FAIL, walletLoginFail),
    takeLatest(actionLabels.EDIT_PROFILE_SAGA, editProfileSaga),
    takeLatest(actionLabels.UPDATE_TOKEN_CLAIM_SAGA, updateTokenClaimSaga),
    takeLatest(actionLabels.FETCH_PROFILE_INFO_SAGA, fetchProfileInfo),
    takeLatest(
      actionLabels.CHECK_USERINFO_AVAIL_SAGA,
      checkUserInfoAvailability
    ),
    takeLatest(actionLabels.NETWORK_SWITCH_SAGA, networkSwitchSaga),
  ]);
}

export function* watchUser() {
  yield all([
    takeLatest(actionLabels.GET_ALL_USERS_SAGA, getAllUsersSaga),
    takeLatest(actionLabels.VIEW_USER_SAGA, viewUserSaga),
  ]);
}

export function* watchIco() {
  yield all([
    takeLatest(actionLabels.FETCH_ICO_INFO_SAGA, fetchIcoInfo),
    takeLatest(actionLabels.POST_TRANS_HASH_SAGA, postTransHash),
    takeLatest(actionLabels.FETCH_TRANSACTIONS_SAGA, fetchTransactions),
    takeLatest(actionLabels.RECORD_PUBLIC_SALE_SAGA, recordPublicSale),
    takeLatest(actionLabels.RECORD_CLAIM_TOKEN_SAGA, recordClaimToken),
    takeLatest(actionLabels.GET_PROOF_SAGA, getProofSaga),
    takeLatest(actionLabels.GET_GAS_FEE_SAGA, getGasFeeSaga),
    takeLatest(actionLabels.GET_TXN_DETAILS_SAGA, getTxnDetailsSaga),
  ]);
}

export function* watchProfile() {
  yield all([
    takeLatest(actionLabels.GET_PROFILE_SAGA, getProfileSaga),
    takeLatest(actionLabels.UPDATE_NUMBER_SAGA, updateNumberSaga),
  ]);
}

export function* watchDashboard() {
  yield all([
    takeLatest(actionLabels.DASHBOARD_STATS_SAGA, dashboardStatsSaga),
  ]);
}

export function* watchAdmin() {
  yield all([
    takeLatest(actionLabels.GET_ALL_ADMIN_SAGA, getAllAdminSaga),
    takeLatest(actionLabels.ADD_ADMIN_SAGA, addAdminSaga),
    takeLatest(actionLabels.DELETE_ADMIN_SAGA, deleteAdminSaga),
    takeLatest(actionLabels.EDIT_ADMIN_SAGA, editAdminSaga),
    takeLatest(actionLabels.ACTIVITY_LOGS_SAGA, activityLogsSaga),
    takeLatest(actionLabels.VIEW_ADMIN_SAGA, viewAdminSaga),
    takeEvery(actionLabels.COMMON_SAGA, commonSaga),
  ]);
}

export function* watchLayout() {
  yield all([
    takeEvery(actionLabels.CHANGE_LAYOUT, changeLayout),
    takeEvery(actionLabels.CHANGE_LAYOUT_WIDTH, changeLayoutWidth),
    takeEvery(actionLabels.CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme),
    takeEvery(actionLabels.CHANGE_SIDEBAR_TYPE, changeLeftSidebarType),
    takeEvery(actionLabels.CHANGE_TOPBAR_THEME, changeTopbarTheme),
    takeEvery(actionLabels.SHOW_RIGHT_SIDEBAR, showRightSidebar),
  ]);
}

export function* watchSubmitFeedback() {
  yield all([
    takeLatest(actionLabels.SUBMIT_FEEDBACK_SAGA, submitFeedbackSaga),
  ]);
}

export function* watchReferral() {
  yield all([
    takeLatest(actionLabels.INVITE_USER_SAGA, inviteUserSaga),
    takeLatest(actionLabels.GET_REFERRAL_LIST_SAGA, getReferralListSaga),
    takeLatest(
      actionLabels.GET_CLAIM_HISTORY_LIST_SAGA,
      getClaimHistoryListSaga
    ),
    takeLatest(actionLabels.CLAIM_RERERRAL_SAGA, claimReferralSaga),
  ]);
}
