import useContract from "./useContract";
import AutoHeadsICOAndVesting from "../contracts/AutoHeadsICOAndVesting.json";

// account is optional
const useAutoHeadsICOAndVestingContract = () =>
  useContract(
    process.env.REACT_APP_ICO_VESTING_CONTRACT_ADDRESS,
    AutoHeadsICOAndVesting.abi,
    true
  );

export default useAutoHeadsICOAndVestingContract;
