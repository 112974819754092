import { useWeb3React } from "@web3-react/core";
import { useActivationState } from "./activate";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { delay } from "../../utils";
import WalletButton from "../../components/WalletButton/WalletButton";
import { setPersistedConnectionMeta } from "../../utils/meta";
import { signMessage } from "../../utils/signmessage";

export default function Option({
  connection,
  signedMessage,
  setSignature = () => null,
  setSignedMessage = () => null,
  setProfileOpen = () => null,
  menuClose = () => null,
  setError = () => null,
  signature,
  type = "",
  setType = () => null,
}) {
  const { activationState, tryActivation } = useActivationState();
  const { chainId, account, isActive, provider, connector } = useWeb3React();
  const activate = () => {
    connector.resetState();
    tryActivation(
      connection,
      () => {
        setPersistedConnectionMeta(connection?.type);
        setType(connection?.type);
      },
      chainId
    );
  };

  const dispatch = useDispatch();
  const signMessageCall = async () => {
    if (provider) {
      signMessage(
        provider,
        account,
        dispatch,
        setProfileOpen,
        setSignedMessage,
        setSignature,
        menuClose,
        setError
      );
    }
  };

  useEffect(() => {
    delay(3000);
    if (
      isActive &&
      account &&
      !signedMessage &&
      !signature &&
      provider &&
      connection?.type === type
    ) {
      signMessageCall();
    } else if (!isActive && !account && signedMessage && signature) {
      setSignedMessage("");
      setSignature("");
    }
  }, [isActive, account, signedMessage, signature, type]);

  const isSomeOptionPending = activationState.status === "PENDING";
  const isCurrentOptionPending =
    isSomeOptionPending &&
    activationState.connection?.type === connection?.type;

  return (
    <div>
      <WalletButton
        title={connection?.getName()}
        logo={connection?.getIcon()}
        onConnectClick={activate}
        isLoading={isCurrentOptionPending}
      />
    </div>
    // <Button
    //   className={`connect-wallet-button selected ${
    //     ((isSomeOptionPending && !isCurrentOptionPending) ||
    //       (account && isActive && type !== connection.type)) &&
    //     "unfollow op-5"
    //   }`}
    //   onClick={activate}
    //   disabled={
    //     isSomeOptionPending || (account && isActive && type !== connection.type)
    //   }
    //   title={
    //     <>
    //       <div className="font-median">{connection?.getName()}</div>
    //       {isCurrentOptionPending ? (
    //         <div>Connecting...</div>
    //       ) : (
    //         <img
    //           src={connection?.getIcon()}
    //           alt={connection?.getName()}
    //           width={20}
    //           height={20}
    //         />
    //       )}
    //     </>
    //   }
    // />
  );
}
