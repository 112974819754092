import React, { Fragment, useEffect, useState } from "react";
import { Button, CardBody, CardTitle, Spinner } from "reactstrap";
import { MDBDataTableV5 } from "mdbreact";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import {
  commonSaga,
  fetchTransactions,
  getProofSaga,
} from "../../store/actions";
import useAutoHeadsICOAndVestingContract from "../../hooks/useAutoHeadsICOAndVesting";
import TrimName from "../../components/trimName/TrimName";
import data from "./data";
import { formatDecimalValue } from "../../utils";

import "./transaction.css";

const PublicSaleRecord = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoHeadsICOAndVestingContract = useAutoHeadsICOAndVestingContract();

  const { userData } = useSelector((state) => state.auth);
  const { allTransaction, proofData, transactionLoader, info } = useSelector(
    (state) => state.ico
  );

  const [claimLoader, setClaimLoader] = useState(false);
  const [txnId, setTxnId] = useState("");

  const url = `/transactions?page=1&limit=10`;

  const toastSettings = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleClaimToken = async (vestingId, transactionId, token, method) => {
    const tokenAmount = info?.Ico?.oneAthdToDollarExpected * token;

    setClaimLoader(true);
    setTxnId(transactionId);
    try {
      const contract = await autoHeadsICOAndVestingContract;
      const claimTxn = await contract?.claimTokens(vestingId, proofData?.items);
      await claimTxn.wait();
      toast.success("Token Claimed successfully", toastSettings);
      const transactionHash = claimTxn?.hash;
      const txnData = {
        transactionHash: transactionHash,
        vestingId: vestingId,
      };
      dispatch(
        commonSaga({
          endPoint: "/ico/save-claim",
          method: "post",
          stateObj: "claimTokenTxn",
          payloadData: txnData,
        })
      );
      dispatch(fetchTransactions({ url }));
    } catch (err) {
      console.log(err);
      if (err?.message && err?.code === 4001) {
        toast.error("Transaction has been declined", toastSettings);
      } else if (err?.message.includes("32603")) {
        toast.error(
          "execution reverted, 0 claimable token amount",
          toastSettings
        );
      } else {
        toast.error("Transaction unsuccessful!", toastSettings);
      }
    } finally {
      setClaimLoader(false);
    }
  };

  const phaseRound = (round) => {
    switch (round) {
      case 0:
        return "Seed Investors";
      case 1:
        return "Strategic 1";
      case 2:
        return "Strategic 2";
      case 3:
        return "Private 1";
      case 4:
        return "Private 2";
      case 5:
        return "Private 3";
      case 6:
        return "Private 4";
      case 7:
        return "Pre-Launch";
      case 8:
        return "Early Bird";
      default:
        return "-";
    }
  };

  const getClaimableAmount = async (vestingId) => {
    try {
      const contract = await autoHeadsICOAndVestingContract;
      const claimableAmount = await contract?.getClaimableTokenAmount(
        vestingId
      );
      if (Number(claimableAmount)) return true;
      else return false;
    } catch (err) {
      console.error(err);
    }
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copied to clipboard!"); // Show success message
      },
      (err) => {
        toast.error("Failed to copy!"); // Show error message
      }
    );
  };

  const handleTransactionData = () => {
    let transactionHistory = [];
    allTransaction?.forEach((item, index) => {
      transactionHistory.push({
        srn: index + 1,
        transactionHash: (
          <div>
            <a
              href={`https://amoy.polygonscan.com/tx/${item?.BlockchainData?.transactionHash}`}
              target="_blank"
            >
              {TrimName(item?.BlockchainData?.transactionHash, 9)}
            </a>
            <i
              className="uil-copy-landscape transaction_copy_icon"
              onClick={() =>
                copyToClipboard(item?.BlockchainData?.transactionHash)
              }
            ></i>
          </div>
        ),
        tokenCount:
          formatDecimalValue(Number(item?.tokenCount)?.toFixed(9)) || "--",
        date: moment(item?.createdAt).format("lll") || "--",
        round: phaseRound(item?.phaseCount),
        claimed: formatDecimalValue(Number(item?.claimed)?.toFixed(9)),
        claimableToken: formatDecimalValue(
          (Number(item?.claimAbleToken) / Math.pow(10, 18)).toFixed(9)
        ),
        method: item?.isFiatPurchase ? "Fiat" : "Crypto",
        action: (
          <div className="d-flex transaction_action">
            <Button
              className="claim_token"
              onClick={() =>
                handleClaimToken(
                  item?.vestingId,
                  item?.transactionId,
                  item?.tokenCount,
                  item?.isFiatPurchase
                )
              }
              disabled={!Number(item?.claimAbleToken) / Math.pow(10, 18)}
            >
              {claimLoader && item?.transactionId === txnId ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Claim"
              )}
            </Button>

            <Button
              className="claim_token"
              onClick={() => navigate(`/claim-history/${item?.vestingId}`)}
            >
              History
            </Button>
          </div>
        ),
      });
    });
    return transactionHistory;
  };

  useEffect(() => {
    dispatch(fetchTransactions({ url }));
    dispatch(getProofSaga());
  }, []);

  return (
    <Fragment>
      <div className="container-fluid">
        <CardBody>
          <CardTitle style={{ marginBottom: "20px", color: "#1a1f29" }}>
            All Transactions
          </CardTitle>
          <MDBDataTableV5
            responsive
            striped
            hover
            bordered
            data={{
              columns: data.columns,
              rows: allTransaction ? handleTransactionData(allTransaction) : [],
            }}
            noRecordsFoundLabel={
              <div className="no_data">
                {transactionLoader ? <Spinner /> : "No transactions found"}
              </div>
            }
            entries={10}
            searching={false}
            entriesOptions={[5, 10, 20]}
            sortable={true}
            pagesAmount={10}
            style={{ fontSize: "14px" }}
            fullPagination
          />
        </CardBody>
      </div>
    </Fragment>
  );
};

export default PublicSaleRecord;
