import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";
import { CardBody } from "reactstrap";

import { claimHisotryColumns } from "./ReferralColumn";

const ClaimHistoryRecord = () => {
  const [rowsData, setRowsData] = useState([]);
  const { claimHistoryList } = useSelector((state) => state?.referral);

  const updateTableData = () => {
    let allTrans = [];
    claimHistoryList?.items.forEach((item, idx) => {
      allTrans.push({
        ...item,
        srn: idx + 1,
        address: item?.userId?.email,
        amount: item?.rewardAmount,
        status: item?.rewardAmount > 0 ? "Active" : "Inactive",
      });
    });
    setRowsData(allTrans);
  };

  useEffect(() => {
    if (claimHistoryList?.items?.length > 0) {
      updateTableData();
    }
  }, [claimHistoryList]);

  return (
    <>
      <div className="container-fluid">
        <CardBody>
          <MDBDataTableV5
            responsive
            striped
            hover
            bordered
            data={{
              columns: claimHisotryColumns,
              rows: rowsData,
            }}
            noRecordsFoundLabel={"No records found."}
            entries={10}
            searching={false}
            entriesOptions={[5, 10, 20]}
            sortable={true}
            pagesAmount={10}
            style={{ fontSize: "14px" }}
            fullPagination
          />
        </CardBody>
      </div>
    </>
  );
};

export default ClaimHistoryRecord;
