import useContract from "./useContract";
import AutoHeadsToken from "../contracts/AutoHeadsToken.json";

// account is optional
const useAutoHeadsTokenContract = () =>
  useContract(
    process.env.REACT_APP_ICO_TOKEN_CONTRACT_ADDRESS,
    AutoHeadsToken.abi,
    true
  );

export default useAutoHeadsTokenContract;
