import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { MoonPayProvider } from "@moonpay/moonpay-react";
import { Web3ReactProvider } from "@web3-react/core";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { persistor, store } from "./store";
import reportWebVitals from "./reportWebVitals";
import { connections } from "./utils/connectors";
import "./connection/eagerlyConnect";

const connectors = connections.map(({ hooks, connector }) => [
  connector,
  hooks,
]);

const container = document.getElementById("root");
const root = createRoot(container);
const { REACT_APP_MOONPAY_API_KEY } = process.env;
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Web3ReactProvider connectors={connectors}>
        <MoonPayProvider
          apiKey={REACT_APP_MOONPAY_API_KEY}
          environment="sandbox"
          debug
        >
          <App />
        </MoonPayProvider>
      </Web3ReactProvider>
    </PersistGate>
  </Provider>
);

// serviceWorker.unregister();
reportWebVitals();
