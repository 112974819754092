import * as actionLabels from "../../actionLabels";

export const initialState = {
  isLoading: false,
  errorMsg: "",
  dashboardStats: null,
};

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionLabels.SET_DEFAULT:
      return { ...state, isLogin: false };

    case actionLabels.DASHBOARD_STATS_START:
      return { ...state, isLoading: true };

    case actionLabels.DASHBOARD_STATS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboardStats: payload.data.items,
        errorMsg: "",
      };
    }
    case actionLabels.DASHBOARD_STATS_FAIL: {
      return { ...state, isLoading: false, errorMsg: payload };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
