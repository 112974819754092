import * as actionLabels from "../../actionLabels";

// INVITE USER

export const inviteUserAction = (payload) => ({
  type: actionLabels.INVITE_USER_SAGA,
  payload,
});

export const inviteUserStart = () => ({
  type: actionLabels.INVITE_USER_START,
});

export const inviteUserSuccess = (payload) => ({
  type: actionLabels.INVITE_USER_SUCCESS,
  payload,
});

export const inviteUserFail = (payload) => ({
  type: actionLabels.INVITE_USER_FAIL,
  payload,
});

// GET_REFERRAL_LIST_SAGA

export const getReferralListAction = (payload) => ({
  type: actionLabels.GET_REFERRAL_LIST_SAGA,
  payload,
});

export const getReferralListStart = () => ({
  type: actionLabels.GET_REFERRAL_LIST_START,
});

export const getReferralListSuccess = (payload) => ({
  type: actionLabels.GET_REFERRAL_LIST_SUCCESS,
  payload,
});

export const getReferralListFail = (payload) => ({
  type: actionLabels.GET_REFERRAL_LIST_FAIL,
  payload,
});

// GET_CLAIM_HISTORY_LIST_SAGA

export const getClaimHistoryListAction = (payload) => ({
  type: actionLabels.GET_CLAIM_HISTORY_LIST_SAGA,
  payload,
});

export const getClaimHistoryListStart = () => ({
  type: actionLabels.GET_CLAIM_HISTORY_LIST_START,
});

export const getClaimHistoryListSuccess = (payload) => ({
  type: actionLabels.GET_CLAIM_HISTORY_LIST_SUCCESS,
  payload,
});

export const getClaimHistoryListFail = (payload) => ({
  type: actionLabels.GET_CLAIM_HISTORY_LIST_FAIL,
  payload,
});

// CLAIM_RERERRAL_SAGA

export const claimReferralAction = (payload) => ({
  type: actionLabels.CLAIM_RERERRAL_SAGA,
  payload,
});

export const claimReferralStart = () => ({
  type: actionLabels.CLAIM_RERERRAL_START,
});

export const claimReferralSuccess = (payload) => ({
  type: actionLabels.CLAIM_RERERRAL_SUCCESS,
  payload,
});

export const claimReferralFail = (payload) => ({
  type: actionLabels.CLAIM_RERERRAL_FAIL,
  payload,
});
