const data = {
  columns: [
    {
      label: "Sr. No.",
      field: "srn",
      sort: "disabled",
      width: 10,
    },

    {
      label: "Transaction Hash",
      field: "transactionHash",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Purchased Token",
      field: "tokenCount",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Date & time",
      field: "date",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Round",
      field: "round",
      sort: "disabled",
      width: 100,
    },
    {
      label: "WT Claimed",
      field: "claimed",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Avail. to claim ",
      field: "claimableToken",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Payment Method",
      field: "method",
      sort: "disabled",
      width: 100,
    },
    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: 100,
    },
  ],

  claimHistoryColumns: [
    {
      label: "Sr. No.",
      field: "srn",
      sort: "disabled",
      width: 10,
    },

    {
      label: "Transaction Hash",
      field: "transactionHash",
      sort: "disabled",
      width: 100,
    },

    {
      label: "Date & time",
      field: "date",
      sort: "disabled",
      width: 100,
    },

    {
      label: "WT Claimed",
      field: "claimed",
      sort: "disabled",
      width: 100,
    },
  ],

  rows: [
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 11,
      fee: 0.000247,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "2 min ago",
      date: "@username",
      to: "@username",
      value: 12,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "5 min ago",
      date: "@username",
      to: "@username",
      value: 5,
      fee: 0.000246,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 6,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 8,
      fee: 0.001245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000249,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 9,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
    {
      hash: "0xekdhd893ksj3902jf283l1n399u23lfs",
      status: "Completed",
      method: "Buy",
      age: "1 min ago",
      date: "@username",
      to: "@username",
      value: 1,
      fee: 0.000245,
      from: "@user",
    },
  ],
};

export default data;
