import {
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_EMAIL_RESEND_MODAL,
  HIDE_EMAIL_RESEND_MODAL,
} from "../../actionLabels";

export const showModal = (payload) => ({
  type: SHOW_MODAL,
  payload,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const showResendEmailModal = (payload) => ({
  type: SHOW_EMAIL_RESEND_MODAL,
  payload,
});

export const hideResendEmailModal = () => ({
  type: HIDE_EMAIL_RESEND_MODAL,
});
