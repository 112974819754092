import React from "react";
import { Modal } from "reactstrap";
import "./ModelUi.css";
const LogoutModal = ({ payload, openClose, handleOpenClose, onSubmit }) => {
  const { title } = payload;

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop() {
    handleOpenClose();
    removeBodyCss();
  }
  return (
    <>
      <Modal
        isOpen={openClose}
        toggle={() => {
          tog_backdrop();
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            <span style={{ marginRight: "10px" }}>
              <img
                src="https://img.icons8.com/external-tal-revivo-fresh-tal-revivo/28/000000/external-online-account-logout-with-arrow-direction-mark-login-fresh-tal-revivo.png"
                alt="Logout"
              ></img>
            </span>
            {title}
          </h5>
          <button
            type="button"
            className="btn-close icon-button"
            onClick={() => {
              tog_backdrop();
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light button is-ghost"
            onClick={() => {
              tog_backdrop();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn button primary_btn"
            onClick={() => {
              onSubmit();
            }}
          >
            Logout
          </button>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
