import React, { useState } from "react";
import BuyCoin from "./BuyCoin";
import IcoPhase from "./IcoPhase";
import { Row, Col } from "reactstrap";
import { Spinner } from "../../components/UI";
import { useSelector } from "react-redux";

const BuyCoinIndex = () => {
  const [transLoader, setTransLoader] = useState(false);
  const { isTransRecordLoading } = useSelector((state) => state.ico);

  const handleTransLoader = () => {
    return setTransLoader(!transLoader);
  };
  if (isTransRecordLoading) {
    return (
      <>
        <div className="page-content">
          <Spinner />
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="dashboard_row">
          <Col lg={6}>
            <BuyCoin
              setTransLoader={setTransLoader}
              transLoader={transLoader}
              handleTransLoader={handleTransLoader}
            />
          </Col>
          <Col lg={6}>
            <IcoPhase />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default BuyCoinIndex;
