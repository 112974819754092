import React from "react";

const AvailabilityDetails = ({ ahtBalance, walletBalance = 0 }) => {
  return (
    <>
      <div className="d-flex inline justify-content-between">
        <ul style={{ marginTop: "1rem", padding: 0 }}>
          <li className="align-items-left list-unstyled py-2 buycoin__stage__sub">
            Token Balance:
          </li>
        </ul>
        <ul style={{ marginTop: "1rem", padding: 0 }}>
          <li className="align-items-left list-unstyled py-2 buycoin__stage__sub">
            <i>{Number(ahtBalance)?.toFixed(9)} WT</i>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AvailabilityDetails;
