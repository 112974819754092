import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";

// import SweetAlert from "react-bootstrap-sweetalert";

import {
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { signupSaga } from "../../store/actions";

import { Link } from "react-router-dom";
import "./signup.css";
import Logo from "../../assets/images/logo/home_2.svg";
import "../../components/Modal/ModelUi.css";
import SignupModal from "../../components/Modal/SignupModal";
import ResendMailModal from "../../components/Modal/ResendMailModal";
import { Spinner } from "../../components/UI";
import useUserAccount from "../../hooks/useUserAccount";
import { disconnectWalletCall } from "../../utils";

const Signup = () => {
  const { connector, isActive } = useWeb3React();
  const [isSignup, setIsSignup] = useState(false);
  const { account } = useUserAccount();

  const urlParams = new URLSearchParams(window.location.search);

  const [details, setDetails] = useState({
    name: "",
    username: account || "",
    email: "",
    terms: false,
    referralCode: "",
  });

  const dispatch = useDispatch();

  const { signature, walletAddress, isLoading } = useSelector(
    (state) => state.auth
  );

  const [verifyEmail, setVerifyEmail] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);

  const disconnectWallet = useCallback(async () => {
    disconnectWalletCall(connector, dispatch);
  }, [connector]);

  useEffect(() => {
    document.body.className = "page_background_color";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const handleModal = () => {
    setVerifyEmail(!verifyEmail);
  };
  const handleResendEmail = () => {
    setResendEmail(!resendEmail);
    if (resendEmail) {
      handleModal(false);
    }
  };

  const handleValidSubmit = () => {
    setIsSignup(true);
    dispatch(
      signupSaga(
        {
          ...details,
          signature,
          data: process.env.REACT_APP_SIGNPHRASE,
          walletAddress,
        },
        handleModal,
        dispatch,
        disconnectWallet
      )
    );
  };

  useEffect(() => {
    const email = urlParams.get("email");
    const code = urlParams.get("code");
    setDetails({ ...details, email: email, referralCode: code });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="signup_body">
          <Spinner />
        </div>
      ) : (
        <React.Fragment>
          <div className="signup_body">
            <Container fluid={true}>
              <div style={{ textAlign: "center" }}>
                <img src={Logo} alt="logo" className="signup___logo__img" />
              </div>

              <Row style={{ padding: "20px 0px" }}>
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <h5 className="signup__title" style={{ color: "#000" }}>
                    Enter Your Details
                  </h5>
                  {/* <Breadcrumbs title="Forms" breadcrumbItem="Enter Your Details" className="signup__title" /> */}
                  <div style={{ padding: "20px 80px" }}>
                    <>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        <div className="mb-3" style={{ color: "#fff" }}>
                          <AvField
                            name="YourName"
                            label="Your Name *"
                            id="YourName"
                            className="abc shadow"
                            type="text"
                            errorMessage="Please enter your name to proceed. This is a required field."
                            validate={{ required: { value: true } }}
                            value={details.name}
                            onChange={(e) => {
                              setDetails({ ...details, name: e.target.value });
                            }}
                          />
                        </div>
                        <div className="mb-3" style={{ color: "#fff" }}>
                          <AvField
                            name="username"
                            label="Username *"
                            className="shadow"
                            id="YourName"
                            type="text"
                            errorMessage="Please enter a valid username. This is a required field."
                            validate={{
                              required: { value: true },
                              // pattern: {
                              //   value: /^s/,
                              //   errorMessage:
                              //     "Username should not have white space",
                              // },
                              pattern: {
                                value: "^[A-Za-z0-9]+$",
                                errorMessage:
                                  "Username must not contain white space and special characters",
                              },
                            }}
                            value={details.username}
                            onChange={(e) => {
                              // cancel process, whitespace found;
                              // e.target.value[e.target.value.length - 1] === " "

                              setDetails({
                                ...details,
                                username: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="mb-3" style={{ color: "#fff" }}>
                          <AvField
                            name="email"
                            label="Email *"
                            id="YourName"
                            type="email"
                            className="shadow"
                            errorMessage="Please enter a valid email address. This is a required field."
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                            value={details.email}
                            onChange={(e) => {
                              setDetails({ ...details, email: e.target.value });
                            }}
                          />
                        </div>

                        <Row>
                          <Col lg="12">
                            <FormGroup className="mb-3">
                              <div
                                className="form-check"
                                style={{ color: "#fff" }}
                              >
                                <Input
                                  type="checkbox"
                                  className="form-check-input shadow"
                                  id="invalidCheck"
                                  checked={details.terms}
                                  onChange={() => {
                                    setDetails({
                                      ...details,
                                      terms: !details.terms,
                                    });
                                  }}
                                />
                                {/* <div className="d-flex justify-content-between"> */}
                                <div>
                                  <Label
                                    className="form-check-label"
                                    htmlFor="invalidCheck"
                                    style={{ color: "#000" }}
                                  >
                                    {" "}
                                    I agree to the{" "}
                                    <Link
                                      // onClick={() => {
                                      //   window.open(
                                      //     "https://beta.globallyunited.io/term-and-condition/"
                                      //   );
                                      // }}
                                      to="#"
                                    >
                                      <u style={{ color: "#183028" }}>
                                        Terms of Service
                                      </u>
                                    </Link>{" "}
                                    and{" "}
                                    <Link
                                      to="#"
                                      // onClick={() => {
                                      //   window.open(
                                      //     "https://beta.globallyunited.io/privacy-and-policy/"
                                      //   );
                                      // }}
                                    >
                                      <u style={{ color: "#183028" }}>
                                        Privacy Policy
                                      </u>
                                    </Link>
                                  </Label>
                                </div>
                              </div>
                              {/* </div> */}
                            </FormGroup>
                          </Col>
                        </Row>
                        {/* <Col sm="12">
                  <Link to="/login">
                    <u>Back to login</u>
                  </Link>
                </Col> */}
                        <div className="d-flex justify-content-between mt-5 align-items-baseline">
                          <div>
                            <Link to="/login" style={{ color: "#000" }}>
                              Already have an account?{"   "}
                              <u style={{ color: "#183028" }}>
                                Click to connect wallet
                              </u>
                            </Link>
                          </div>
                          <div>
                            <Col sm="12">
                              <Button
                                // color="primary"
                                type="submit"
                                className="w-lg primary_btn loginCard"
                                // style={{
                                //   backgroundImage:
                                //     "linear-gradient(90deg,#76356a 0,#faa61a)",
                                //   borderRadius: "4px",
                                //   border: "none",
                                // }}
                                disabled={
                                  details.name &&
                                  details.email &&
                                  details.username &&
                                  details.terms
                                    ? false
                                    : true
                                }
                                // onClick={() => {
                                //   dispatch(
                                //     signupSaga(
                                //       {
                                //         ...details,
                                //         signature,
                                //         data: process.env.REACT_APP_SIGNPHRASE,
                                //         walletAddress,
                                //       },
                                //       handleModal,
                                //       dispatch,
                                //       deactivate
                                //     )
                                //   );
                                // }}
                              >
                                Submit
                              </Button>
                            </Col>
                          </div>
                        </div>
                      </AvForm>
                    </>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {verifyEmail && (
            <SignupModal
              payload={{
                title: "A Verification link has been sent to your email ",
              }}
              handleOpenClose={handleModal}
              openClose={verifyEmail}
              handleResendEmail={handleResendEmail}
            />
          )}
          {resendEmail ? (
            <ResendMailModal
              payload={{
                title: "Didn't receive verification link?",
              }}
              handleResendEmail={handleResendEmail}
              openClose={resendEmail}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default Signup;
