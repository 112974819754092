import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import { connect, useDispatch } from "react-redux";
import { Form, Input, Button, Spinner } from "reactstrap";
import "./Footer.css";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// Reactstrap
import { Dropdown, DropdownMenu } from "reactstrap";

import moment from "moment";

//web3
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
//i18n
import { withTranslation } from "react-i18next";

// Import menuDropdown

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  updateTokenClaimSaga,
  recordClaimToken,
} from "../../store/actions";

import useUserAccount from "../../hooks/useUserAccount";
import { connections } from "../../utils/connectors";
import { disconnectWalletCall } from "../../utils";

const toastSettings = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
const Header = (props) => {
  const { userData } = useSelector((state) => state.auth);

  const { account } = useUserAccount();

  const dispatch = useDispatch();

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  useEffect(() => {
    // tToggle();
  }, []);

  localStorage.setItem("wallet_address", account);

  function reduceAddressSize(address) {
    return address.length > 35
      ? `${address.substr(0, 5)}...${address.substr(
          address.length - 4,
          address.length
        )}`
      : address;
  }

  function walletImage() {
    const walletConnected = localStorage.getItem("connection_meta");
    if (walletConnected === "INJECTED") return connections[0]?.getIcon();
    if (walletConnected === "COINBASE_WALLET") return connections[1]?.getIcon();
    if (walletConnected === "WALLET_CONNECT_V2")
      return connections[2]?.getIcon();
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <div style={{ padding: "15px" }} className="pe-0">
              <Button
                // color="secondary"
                style={{ backgroundColor: "#1a1f29", opacity: "1" }}
                className="btn-rounded waves-effect waves-light border-0"
              >
                <img
                  src={walletImage()}
                  alt="Wallet"
                  style={{ width: "20px" }}
                ></img>{" "}
                {userData && reduceAddressSize(userData.walletAddress)}
              </Button>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
