import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

import "./transaction.css";
import PublicSaleRecord from "./PublicSaleRecord";

const { REACT_APP_EXPLORERLINK } = process.env;

const SaleRecord = () => {
  return (
    <div className="page-content">
      <div className="table_card">
        <PublicSaleRecord explorerurl={REACT_APP_EXPLORERLINK} />
      </div>
      {/* <Card>
        <CardBody>
          <CardTitle className="h4">Custom Tabs</CardTitle>
          <p className="card-title-desc">Example of custom tabs</p>

          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#012611" }}
                className={classnames({
                  active: activeTabJustify === "1",
                })}
                onClick={() => {
                  toggleCustomJustified("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">Public Sale</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#012611" }}
                className={classnames({
                  active: activeTabJustify === "2",
                })}
                onClick={() => {
                  toggleCustomJustified("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-user"></i>
                </span>
                <span className="d-none d-sm-block">Private Sale</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer", color: "#012611" }}
                className={classnames({
                  active: activeTabJustify === "3",
                })}
                onClick={() => {
                  toggleCustomJustified("3");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="far fa-envelope"></i>
                </span>
                <span className="d-none d-sm-block">Closed Sale</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent >
            <TabPane tabId="1" className="p-3">
              <PublicSaleRecord
                transactions={allTransaction}
                explorerurl={REACT_APP_EXPLORERLINK}
              />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <PrivateSaleRecord
                transactions={privateTransaction}
                explorerurl={REACT_APP_EXPLORERLINK}
              />
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <ClosedSaleRecord
                transactions={closedTransaction}
                explorerurl={REACT_APP_EXPLORERLINK}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card> */}
    </div>
  );
};

export default SaleRecord;
