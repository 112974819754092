import React from "react";
import { Modal } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "./ModelUi.css";

const SignupRedirectModal = ({ payload, openClose, handleOpenClose }) => {
  const { title } = payload;
  const navigate = useNavigate();
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop() {
    handleOpenClose();
    removeBodyCss();
  }
  return (
    <>
      <Modal
        isOpen={openClose}
        toggle={() => {
          tog_backdrop();
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            {title}
          </h5>

          <button
            type="button"
            className="btn-close icon-button"
            onClick={() => {
              tog_backdrop();
            }}
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          Account not registered, please click register to continue!
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light button is-ghost"
            onClick={() => {
              tog_backdrop();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn button primary_btn"
            onClick={() => navigate(`/signup${window.location.search}`)}
          >
            Register
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SignupRedirectModal;
