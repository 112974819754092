import PropTypes from "prop-types";
import React, { Component } from "react";
import { useNavigate } from "react-router-dom";

import LoginNav from "../views/Login/LoginNav";
import { HOME_BG } from "../assets/images";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string?.charAt(1)?.toUpperCase() + string?.slice(2);
  };

  componentDidMount() {}
  render() {
    return (
      <div class="video-container">
        {/* <video autoPlay muted loop id="video-bg" src={HOME_BG} /> */}
        {/* <source src={HOME_BG} type="video/mp4"/>       */}
        {/* </video>   */}

        <div className="content">
          {/* <LoginNav /> */}
          <div>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
