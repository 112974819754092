export const FETCH_ICO_INFO_SAGA = "FETCH_ICO_INFO_SAGA";
export const FETCH_ICO_INFO_START = "FETCH_ICO_INFO_START";
export const FETCH_ICO_INFO_SUCCESS = "FETCH_ICO_INFO_SUCCESS";
export const FETCH_ICO_INFO_FAIL = "FETCH_ICO_INFO_FAIL";

export const FETCH_ICO_INFO_IN_INTERVAL_SAGA =
  "FETCH_ICO_INFO_IN_INTERVAL_SAGA";
export const FETCH_ICO_INFO_IN_INTERVAL_START =
  "FETCH_ICO_INFO_IN_INTERVAL_START";
export const FETCH_ICO_INFO_IN_INTERVAL_SUCCESS =
  "FETCH_ICO_INFO_IN_INTERVAL_SUCCESS";
export const FETCH_ICO_INFO_IN_INTERVAL_FAIL =
  "FETCH_ICO_INFO_IN_INTERVAL_FAIL";

export const POST_TRANS_HASH_START = "POST_TRANS_HASH_START";
export const POST_TRANS_HASH_SAGA = "POST_TRANS_HASH_SAGA";
export const POST_TRANS_HASH_SUCCESS = "POST_TRANS_HASH_SUCCESS";
export const POST_TRANS_HASH_FAIL = "POST_TRANS_HASH_FAIL";

export const RECORD_PUBLIC_SALE_START = "RECORD_PUBLIC_SALE_START";
export const RECORD_PUBLIC_SALE_SAGA = "RECORD_PUBLIC_SALE_SAGA";
export const RECORD_PUBLIC_SALE_SUCCESS = "RECORD_PUBLIC_SALE_SUCCESS";
export const RECORD_PUBLIC_SALE_FAIL = "RECORD_PUBLIC_SALE_FAIL";

export const FETCH_TRANSACTIONS_START = "FETCH_TRANSACTIONS_START";
export const FETCH_TRANSACTIONS_SAGA = "FETCH_TRANSACTIONS_SAGA";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const FETCH_TRANSACTIONS_FAIL = "FETCH_TRANSACTIONS_FAIL";

export const RECORD_CLAIM_TOKEN_START = "RECORD_CLAIM_TOKEN_START";
export const RECORD_CLAIM_TOKEN_SAGA = "RECORD_CLAIM_TOKEN_SAGA";
export const RECORD_CLAIM_TOKEN_SUCCESS = "RECORD_CLAIM_TOKEN_SUCCESS";
export const RECORD_CLAIM_TOKEN_FAIL = "RECORD_CLAIM_TOKEN_FAIL";

export const GET_PROOF_START = "GET_PROOF_START";
export const GET_PROOF_SAGA = "GET_PROOF_SAGA";
export const GET_PROOF_SUCCESS = "GET_PROOF_SUCCESS";
export const GET_PROOF_FAIL = "GET_PROOF_FAIL";

export const GET_GAS_FEE_START = "GET_GAS_FEE_START";
export const GET_GAS_FEE_SAGA = "GET_GAS_FEE_SAGA";
export const GET_GAS_FEE_SUCCESS = "GET_GAS_FEE_SUCCESS";
export const GET_GAS_FEE_FAIL = "GET_GAS_FEE_FAIL";

export const SET_DOLLAR_RATE = "SET_DOLLAR_RATE";

export const GET_TXN_DETAILS_START = "GET_TXN_DETAILS_START";
export const GET_TXN_DETAILS_SAGA = "GET_TXN_DETAILS_SAGA";
export const GET_TXN_DETAILS_SUCCESS = "GET_TXN_DETAILS_SUCCESS";
export const GET_TXN_DETAILS_FAIL = "GET_TXN_DETAILS_FAIL";
