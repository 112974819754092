import React from "react";
import { Modal } from "reactstrap";
import "./ModelUi.css";

const TransactionSuccess = ({ open, handleTxnModal }) => {
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop() {
    handleTxnModal();
    removeBodyCss();
  }
  return (
    <>
      <Modal
        isOpen={open}
        toggle={() => {
          tog_backdrop();
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <button
            type="button"
            className="btn-close icon-button"
            onClick={() => {
              tog_backdrop();
            }}
            aria-label="Close"
          ></button>
        </div>
        <div className="txn_body">
          <div>
            <h2 className="txn_success">Transaction Successful!</h2>
            <p>Your transaction has been processed successfully.</p>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn button primary_btn"
            onClick={() => {
              tog_backdrop();
            }}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TransactionSuccess;
