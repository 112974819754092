import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, CardBody, Container } from "reactstrap";

import "./BuyCoin.css";

const CountDown = () => {
  
  const { nextPhaseStartDate } = useSelector((state) => state.ico);
    
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const targetTime = new Date(nextPhaseStartDate).getTime();
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      // Countdown has reached or passed the target date
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [nextPhaseStartDate]);

  return (
    <React.Fragment>
      
      
          <CardBody style={{ padding: "0 24px"}}>        
              <div>
                <div
                  className="ico_title m-0"
                  style={{ paddingBottom: "10px" }}
                >
                 End In
                </div>

                <Container>
                  <Row sm="7" className="count__down__buytimer">               

                    <Col
                      className="buy__coin__shadow buy__coin__countdown buy__coin__countcolour mx-2 py-2"
                      style={{ marginTop: "10px" }}
                    >
                      <h2 className="buy__coin__header">
                        {timeLeft.days || "0"}
                      </h2>
                      <p>Days</p>
                    </Col>

                    <Col
                      className="buy__coin__shadow buy__coin__countdown buy__coin__countcolour mx-2 py-2"
                      style={{ marginTop: "10px" }}
                    >
                      <h2 className="buy__coin__header">
                        {timeLeft.hours || "0"}
                      </h2>
                      <p>Hours</p>
                    </Col>

                    <Col
                      className="buy__coin__shadow buy__coin__countdown buy__coin__countcolour mx-2 py-2"
                      style={{ marginTop: "10px" }}
                    >
                      <h2 className="buy__coin__header">
                        {timeLeft.minutes || "0"}
                      </h2>
                      <p>Minutes</p>
                    </Col>

                    <Col
                      className="buy__coin__shadow buy__coin__countdown buy__coin__countcolour mx-2 py-2"
                      style={{ marginTop: "10px" }}
                    >
                      <h2 className="buy__coin__header">
                        {timeLeft.seconds || "0"}
                      </h2>
                      <p>Seconds</p>
                    </Col>
                  </Row>
                </Container>
              </div>
            
          </CardBody>
      
   
    </React.Fragment>
  );
};

export default CountDown;
