import { toast } from "react-toastify";
import { fetchProfileInfo, loginSaga, resetApp } from "../store/actions";
import { clearConnectorStorage } from "./web3/web3";

const accountChange = async (
  connector,
  provider,
  account,
  dispatch = () => null
) => {
  if (!provider) return;
  
  const logout = (message) => {
    localStorage.clear();
    sessionStorage.clear();
    clearConnectorStorage(connector);
    connector.resetState();
    dispatch(resetApp());
    toast.error(message)
  };

  const fetchProfile = () => {
    dispatch(fetchProfileInfo());
  };

  localStorage.removeItem("signature");
  console.log(account);
  let signature = null;
  try {
    signature = await provider.send("personal_sign", [
      process.env.REACT_APP_SIGNPHRASE,
      account,
    ]);
  } catch (error) {
    console.error(error);
    logout()
  }

  localStorage.setItem("signature", signature?.result || signature);
  const data = {
    signature: signature?.result || signature,
    loginType: "signature",
    data: process.env.REACT_APP_SIGNPHRASE,
    walletAddress: account,
  };
  dispatch(loginSaga({ payload: data, success: fetchProfile, logout }));
};

export default accountChange;
