import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { fetchProfileInfo } from "../../store/actions";
import Doughnut from "../AllCharts/echart/doughnutchart";
import { useWeb3React } from "@web3-react/core";
import IcoPhase from "../BuyCoin/IcoPhase";
import { disconnectWalletCall } from "../../utils";

const DashboardChart = () => {
  const dispatch = useDispatch();
  const { connector } = useWeb3React();

  const disconnectWallet = useCallback(async () => {
    disconnectWalletCall(connector, dispatch);
  }, [connector]);

  useEffect(() => {
    dispatch(fetchProfileInfo(disconnectWallet));
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row className="dashboard_row">
          <Col lg="6">
            <Card className="h-100">
              <CardBody>
                <CardTitle
                  className="mb-4 section_header"
                  style={{ marginLeft: "20px" }}
                >
                  My Portfolio
                </CardTitle>
                <div id="doughnut-chart" className="e-chart">
                  <Doughnut />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            <IcoPhase />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DashboardChart;
