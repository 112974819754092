import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer footer-color">
        <Container fluid={true}>
          <Row>
            {/* <Col sm={12} className="">
              <div>
                <ul className="d-flex inline justify-content-between">
                  <li
                    className="align-items-left list-unstyled"
                    style={{ color: "#495057", fontSize: "15px" }}
                  >
                    Home
                  </li>
                  <li
                    className="list-unstyled"
                    style={{ color: "#495057", fontSize: "15px" }}
                  >
                    FAQ
                  </li>
                  <li
                    className="list-unstyled"
                    style={{ color: "#495057", fontSize: "15px" }}
                  >
                    Terms and Condictions
                  </li>
                  <li
                    className="list-unstyled"
                    style={{ color: "#495057", fontSize: "15px" }}
                  >
                    {" "}
                    Privacy Policies
                  </li>
                </ul>
              </div>
            </Col> */}
            <Col md={6} sm={12} className="footer__copy__right">
              Copyright Reserve © Blockthree Holdings ltd{" "}
              {new Date().getFullYear()}
            </Col>
            <Col md={6} sm={12}>
              <div className="footer__head">
                <Link
                  to="/about-us"
                  className="footer__link"
                  style={{ color: "#383838" }}
                >
                  {" "}
                  About Us
                </Link>
                <Link
                  to="/faq"
                  className="footer__link"
                  style={{ color: "#383838" }}
                >
                  FAQ's
                </Link>
                <Link
                  to="/terms-and-condition"
                  className="footer__link"
                  style={{ color: "#383838" }}
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/privacy-policy"
                  className="footer__link"
                  style={{ color: "#383838" }}
                >
                  Privacy Policy
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Footer;
