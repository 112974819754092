import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody } from "reactstrap";
import Avatar from "react-avatar";
// import avatar1 from "../../assets/images/users/avatar-1.jpeg";

const ProfileInfo = () => {
  const { userData } = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <Card className="card" style={{ padding: "80px 80px 135px 80px" }}>
        <CardBody>
          <div className="text-center">
            <div className="clearfix"></div>
            <div>
              <Avatar
                name={userData ? userData.name : ""}
                color="#183028"
                className="avatar-lg rounded-circle"
                // className="circle"
              />
              {/* <img
                src={avatar1}
                alt=""
                className="avatar-lg rounded-circle img-thumbnail"
              /> */}
            </div>
            <h5 className="mt-3 mb-1" style={{ color: "#383838" }}>
              {userData && userData.name}
            </h5>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ProfileInfo;
