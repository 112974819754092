const FAQ = () => {
  return (
    <div>
      <div className="cms_title ">FAQ</div>
      <div className="cms_content opacity-100">
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.
        <br />
        <br /> If you choose to use our Service, then you agree to the
        collection and use of information in relation to this policy. The
        Personal Information that we collect is used for providing and improving
        the Service. We will not use or share your information with anyone
        except as described in this Privacy Policy.
        <br />
        <br /> The terms used in this Privacy Policy have the same meanings as
        in our Terms and Conditions, which are accessible at 3VERSE unless
        otherwise defined in this Privacy Policy.
        <br />
        <br />
        <b>Information Collection and Use </b>
        <br />
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information, including
        but not limited to email, wallet addresses. The information that we
        request will be retained by us and used as described in this privacy
        policy.
        <br />
        <br /> The app does use third-party services that may collect
        information used to identify you.
        <br />
        <br />
        <b>Log Data</b>
        <br />
        We want to inform you that whenever you use our Service, in a case of an
        error in the app we collect data and information (through third-party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing our Service, the time and date of your use of the Service, and
        other statistics.
        <br />
        <br />
        <b>Cookies</b>
        <br />
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device&apos;s internal
        memory
        <br />
        <br />
        This Service does not use these “cookies” explicitly. However, the app
        may use third-party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
        <br />
        <br />
        <b>Service Providers</b>
        <br />
        <br />
        We may employ third-party companies and individuals due to the following
        reasons:
        <br />
        <br />
        To facilitate our Service;
        <br />
        To provide the Service on our behalf;
        <br /> To perform Service-related services; or <br />
        To assist us in analyzing how our Service is used.
        <br />
        <br />
        We want to inform users of this Service that these third parties have
        access to their Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </div>
    </div>
  );
};

export default FAQ;
