export const connectionMetaKey = "connection_meta";

export function getPersistedConnectionMeta() {
  try {
    const value = localStorage.getItem(connectionMetaKey);
    if (value) return value;
    return;
  } catch (e) {
    console.warn(e);
  }
  return;
}

export function setPersistedConnectionMeta(meta) {
  localStorage.setItem(connectionMetaKey, meta);
}

export function deletePersistedConnectionMeta() {
  localStorage.removeItem(connectionMetaKey);
}
