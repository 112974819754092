import React, { Fragment, useEffect, useState } from "react";
import { Button, CardBody, CardTitle, Spinner } from "reactstrap";
import { MDBDataTableV5 } from "mdbreact";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import {
  fetchTransactions,
  getProofSaga,
  getTxnDetailsSaga,
} from "../../store/actions";
import TrimName from "../../components/trimName/TrimName";
import data from "./data";
import { formatDecimalValue } from "../../utils";

import "./transaction.css";

const PrivateSaleRecord = () => {
  const dispatch = useDispatch();
  const { vestingId } = useParams();
  const { allTransaction, transactionLoader, txnDetails, txnDetailLoader } =
    useSelector((state) => state.ico);

  const url = `/transactions/claim-history?vestingId=${vestingId}&page=1&limit=10`;

  const phaseRound = (round) => {
    switch (round) {
      case 0:
        return "Seed Investors";
      case 1:
        return "Strategic 1";
      case 2:
        return "Strategic 2";
      case 3:
        return "Private 1";
      case 4:
        return "Private 2";
      case 5:
        return "Private 3";
      case 6:
        return "Private 4";
      case 7:
        return "Pre-Launch";
      case 8:
        return "Early Bird";
      default:
        return "-";
    }
  };

  const handleTransactionData = () => {
    let transactionHistory = [];
    allTransaction?.forEach((item, index) => {
      transactionHistory.push({
        srn: index + 1,
        transactionHash: (
          <Link
            to={`https://amoy.polygonscan.com/tx/${item?.BlockchainData?.transactionHash}`}
            target="_blank"
          >
            {TrimName(item?.BlockchainData?.transactionHash, 9)}
          </Link>
        ),
        date: moment(item?.createdAt).format("lll") || "--",
        claimed: formatDecimalValue(
          Number(item?.BlockchainData?.tokenCount)?.toFixed(9)
        ),
      });
    });
    return transactionHistory;
  };

  useEffect(() => {
    dispatch(fetchTransactions({ url }));
    dispatch(getTxnDetailsSaga({ id: vestingId }));
  }, []);

  return (
    <Fragment>
      <CardBody className="claim_history_table">
        <CardTitle style={{ marginBottom: "20px" }}>
          <Link className="txn_link" to="/transaction">
            All Transactions
          </Link>{" "}
          {" > "} Claim History
        </CardTitle>
        <div className="txn_details">
          <div className="txn_data">
            <div className="fw-bold w-25"> Buyer Name </div> :
            <div className="ms-2"> {txnDetails?.name} </div>
          </div>
          <div className="txn_data">
            <div className="fw-bold w-25"> Token Amount </div> :
            <div className="ms-2">
              {" "}
              {formatDecimalValue(txnDetails?.tokenCount.toFixed(9))} WT
            </div>
          </div>
          <div className="txn_data">
            <div className="fw-bold w-25"> Payment Method </div>:
            <div className="ms-2">
              {" "}
              {txnDetails?.isFiatPurchase ? "MoonPay" : "Crypto"}{" "}
            </div>
          </div>
          <div className="txn_data">
            <div className="fw-bold w-25"> POL Price </div>:{" "}
            <div className="ms-2">
              {formatDecimalValue(
                Number(txnDetails?.BlockchainData?.etherValue)?.toFixed(9)
              )}{" "}
              POL{" "}
            </div>
          </div>
          <div className="txn_data">
            <div className="fw-bold w-25"> Wallet Address </div>:
            <div className="ms-2"> {txnDetails?.walletAddress} </div>
          </div>
        </div>
        <MDBDataTableV5
          responsive
          striped
          hover
          bordered
          data={{
            columns: data.claimHistoryColumns,
            rows: allTransaction ? handleTransactionData(allTransaction) : [],
          }}
          noRecordsFoundLabel={
            <div className="no_data">
              {transactionLoader ? <Spinner /> : "No transactions found"}
            </div>
          }
          entries={10}
          searching={false}
          entriesOptions={[5, 10, 20]}
          sortable={true}
          pagesAmount={10}
          style={{ fontSize: "14px" }}
          fullPagination
        />
      </CardBody>
    </Fragment>
  );
};

export default PrivateSaleRecord;
