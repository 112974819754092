import { CHECKBOX, CONTACT_PNG, EDIT_ICON } from "../../assets/images";

export const RewardOptionArray = [
  {
    index: 0,
    title: "REFER A FRIEND",
    color: "green",
    text: "Share reference to every friend",
    icon: CONTACT_PNG,
    font: "#1fb141",
  },
  {
    index: 1,
    title: "MAKE SIGN UP",
    color: "blue",
    text: "Make friend signup into the platform",
    icon: EDIT_ICON,
    font: "#42a2f1",
  },
  {
    index: 2,
    title: "DO ENROLLS",
    color: "orange",
    text: "Make friend to participate into ICO",
    icon: CHECKBOX,
    font: "#ff960a",
  },
];

export const referralRecordColumns = [
  {
    label: "Sr. No.",
    field: "srn",
    sort: "disabled",
    width: 10,
  },
  {
    label: "Referral Email",
    field: "email",
    sort: "disabled",
    width: 100,
  },
  {
    label: "Date",
    field: "date",
    sort: "disabled",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    sort: "disabled",
    width: 100,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "disabled",
    width: 100,
  },
];

export const claimHisotryColumns = [
  {
    label: "Sr. No.",
    field: "srn",
    sort: "disabled",
    width: 10,
  },
  {
    label: "Address",
    field: "address",
    sort: "disabled",
    width: 100,
  },
  {
    label: "Amount",
    field: "amount",
    sort: "disabled",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    sort: "disabled",
    width: 100,
  },
];
