import ethereumLogoUrl from "../assets/images/ethereum-logo.png";
import { ChainId } from "./chains";

const CHAIN_INFO = {
  [ChainId.MAINNET]: {
    // networkType: NetworkType.L1,
    docs: "https://docs.uniswap.org/",
    explorer: "https://etherscan.io/",
    infoLink: "https://info.uniswap.org/#/",
    label: "Ethereum",
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    // color: darkTheme.chain_1,
  },
  [ChainId.HARDHAT]: {
    docs: "",
    explorer: "",
    infoLink: "",
    label: "Hardhat",
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: "Hardhat", symbol: "GO", decimals: 18 },
  },
};

export function getChainInfo(chainId, featureFlags) {
  if (featureFlags && chainId in featureFlags) {
    return featureFlags[chainId] ? CHAIN_INFO[chainId] : undefined;
  }
  if (chainId) {
    return CHAIN_INFO[chainId] ?? undefined;
  }
  return undefined;
}
