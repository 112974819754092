import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Spinner } from "reactstrap";
// import { Spinner } from "../UI";
import { toast } from "react-toastify";

import getWeb3 from "../../utils/getWeb3";
import "./ModelUi.css";

const toastSettings = {
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const ConfirmModal = ({
  payload,
  openClose,
  handleOpenClose,
  onSubmit,
  guValue,
  transLoader,
  setTransLoader,
}) => {
  // const { isLoading } = useSelector((state) => state.ico);
  const { walletAddress } = useSelector((state) => state.auth);

  const { title, body } = payload;

  const [count, setCount] = useState(3);

  useEffect(() => {
    async function getWalBalance() {
      let web3 = await getWeb3();
      // console.log("WEB######3", web3);
      try {
        await web3?.eth?.getBalance(walletAddress).then((val) => {
          if (val >= 0) {
            const balance = val / 1000000000000000000;
            // console.log("balcheck guvalueee", guValue);
            // console.log("balcheck Balance", balance);
            if (guValue > balance) {
              toast.warning(
                "Insufficient funds in your account. Please try again later.",
                toastSettings
              );
              handleOpenClose();
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    }

    getWalBalance();
  }, [walletAddress]);

  // assuming we will be getting the response by this time
  useEffect(() => {
    if (count !== 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
  }, [count]);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop() {
    handleOpenClose();
    removeBodyCss();
  }
  // if (isLoading)
  //   return (
  //     <>
  //       <div className="page-content">
  //         <Spinner />
  //       </div>
  //     </>
  //   );

  return (
    <>
      <Modal
        isOpen={openClose}
        toggle={() => {
          if (!transLoader) tog_backdrop();
        }}
        scrollable={true}
        id="staticBackdrop"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            <img
              src="https://img.icons8.com/external-tal-revivo-green-tal-revivo/36/000000/external-verification-tick-mark-for-digital-certification-document-basic-green-tal-revivo.png"
              alt="Confirm"
            ></img>
            {"    "}
            <b>{title}</b>
          </h5>
          {!transLoader && (
            <button
              type="button"
              className="btn-close icon-button"
              onClick={() => {
                tog_backdrop();
              }}
              aria-label="Close"
            ></button>
          )}
        </div>
        <div className="modal-body">
          {body}
          {transLoader && (
            <b>
              <br />
              <br />
              Please do not close the browser while the transaction is
              processing
            </b>
          )}
        </div>

        <div className="modal-footer">
          {!transLoader && (
            <button
              type="button"
              className="btn btn-light button is-ghost"
              onClick={() => {
                tog_backdrop();
              }}
            >
              Cancel
            </button>
          )}
          {count || transLoader ? (
            <div>
              {transLoader && (
                <button
                  type="button"
                  className="btn btn-light button is-ghost me-2"
                  onClick={() => {
                    tog_backdrop();
                    setTransLoader(false);
                  }}
                >
                  Close
                </button>
              )}
              <button className="btn btn-primary button is-primary">
                {transLoader ? (
                  <>
                    <Spinner size="sm" /> Transaction awaited..
                  </>
                ) : (
                  <>
                    <Spinner size="sm" /> Please wait..
                  </>
                )}
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-primary button is-primary"
              onClick={() => {
                onSubmit();
              }}
            >
              Proceed
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
