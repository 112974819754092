import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Card, CardBody, CardTitle, Modal } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { useWeb3React } from "@web3-react/core";

import { editProfileSaga, fetchProfileInfo } from "../../store/actions";
import ProfileInfo from "./ProfileInfo";
import { disconnectWalletCall } from "../../utils";

const MyProfile = () => {
  const { userData } = useSelector((state) => state.auth);
  const [modalStandard, setModalStandard] = useState(false);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const { connector } = useWeb3React();

  function togStandard() {
    setModalStandard(!modalStandard);
  }

  // useEffect(() => {
  //   dispatch(fetchProfileInfo(deactivate));
  // }, []);

  // useEffect(() => {
  //   if (msgStatus === "success") togStandard();
  // }, [msgStatus]);

  const disconnectWallet = useCallback(async () => {
    disconnectWalletCall(connector, dispatch);
  }, [connector]);

  const handleSuccess = () => {
    dispatch(fetchProfileInfo());
  };

  useEffect(() => {
    if (userData) {
      setEmail(userData?.email);
      setName(userData?.name);
      setUsername(userData?.username);
    }
  }, [userData]);

  return (
    <React.Fragment>
      <Row>
        <Col xl="4">
          <ProfileInfo />
        </Col>
        <Col>
          <Card>
            <CardBody>
              <CardTitle style={{ color: "#383838" }}>
                <div className="d-flex justify-content-between">
                  Profile Information{"  "}
                  <span
                    className="mdi mdi-pencil font-size-18"
                    onClick={() => {
                      togStandard();
                    }}
                    data-toggle="modal"
                    data-target="#myModal"
                    style={{
                      cursor: "pointer",
                      color: "#012611",
                    }}
                  >
                    <u style={{ fontSize: "14px" }}>Edit Profile</u>
                  </span>
                </div>
              </CardTitle>

              <br />

              <Row className="mb-4">
                <label
                  htmlFor="example-text-input"
                  className="col-md-4 col-form-label general_text"
                  // style={{ color: "#383838" }}
                >
                  Name
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    style={{ color: "#383838" }}
                    type="text"
                    value={userData && userData?.name}
                    disabled
                  />
                </div>
              </Row>
              <Row className="mb-4">
                <label
                  htmlFor="example-search-input"
                  className="col-md-4 col-form-label general_text"
                  // style={{ color: "#383838" }}
                >
                  Wallet Address
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="search"
                    style={{ color: "#383838" }}
                    value={userData && userData?.walletAddress}
                    disabled
                  />
                </div>
              </Row>
              <Row className="mb-4">
                <label
                  htmlFor="example-email-input"
                  className="col-md-4 col-form-label general_text"
                  // style={{ color: "#383838" }}
                >
                  Email Address
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="email"
                    style={{ color: "#383838" }}
                    value={userData && userData?.email}
                    disabled
                  />
                </div>
              </Row>
              <Row className="mb-4" style={{ paddingBottom: "50px" }}>
                <label
                  htmlFor="example-url-input"
                  className="col-md-4 col-form-label general_text"
                  // style={{ color: "#383838" }}
                >
                  Username
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    style={{ color: "#383838" }}
                    type="url"
                    value={userData && userData?.username}
                    disabled
                  />
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={modalStandard}
        toggle={() => {
          togStandard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="popup_title">
            Edit Profile
          </h5>
          <button
            type="button"
            onClick={() => {
              setModalStandard(false);
            }}
            className="close icon-button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AvForm>
            <Row className="mb-4">
              <label
                htmlFor="example-text-input"
                className="col-md-4 col-form-label general_text"
                // style={{ color: "#383838" }}
              >
                Name
              </label>
              <div className="col-md-8">
                {/* <input
          <Row className="mb-4">
            <label
              htmlFor="example-text-input"
              className="col-md-4 col-form-label"
              style={{ color: "#383838" }}
            >
              Name
            </label>
            <div className="col-md-8">
              <input
                className="form-control"
                style={{ color: "#383838" }}
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                
              /> */}
                <AvField
                  name="name"
                  type="text"
                  errorMessage="Name is required"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </div>
            </Row>
            <Row className="mb-4">
              <label
                htmlFor="example-email-input"
                className="col-md-4 col-form-label general_text"
                // style={{ color: "#383838" }}
              >
                Email Address
              </label>
              <div className="col-md-8">
                {/* <input
                  className="form-control"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                /> */}
                {/* <div className="mb-3"> */}
                <AvField
                  name="email"
                  // label="Email *"
                  type="email"
                  errorMessage="Invalid Email"
                  validate={{
                    email: { value: true },
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  disabled
                />
                {/* </div> */}
              </div>
            </Row>
            <Row className="mb-4" style={{ paddingBottom: "50px" }}>
              <label
                htmlFor="example-url-input"
                className="col-md-4 col-form-label general_text"
                // style={{ color: "#383838" }}
              >
                Username
              </label>
              <div className="col-md-8">
                {/* <input
              />
            </div>
          </Row>

          <Row className="mb-4">
            <label
              htmlFor="example-email-input"
              className="col-md-4 col-form-label"
              style={{ color: "#383838" }}
            >
              Email Address
            </label>
            <div className="col-md-8">
              <input
                className="form-control"
                style={{ color: "#383838" }}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </Row>
          <Row className="mb-4" style={{ paddingBottom: "50px" }}>
            <label
              htmlFor="example-url-input"
              style={{ color: "#383838" }}
              className="col-md-4 col-form-label"
            >
              Username
            </label>
            <div className="col-md-8">
              <input
                className="form-control"
                style={{ color: "#383838" }}
                type="url"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              /> */}
                <AvField
                  name="username"
                  type="text"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
            </Row>
          </AvForm>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              togStandard();
            }}
            className="btn  button is-ghost"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn button primary_btn"
            onClick={() => {
              const payload = {};
              if (name && name !== userData?.name) payload.name = name;
              if (email && email !== userData?.email) payload.email = email;
              if (username && username !== userData?.username)
                payload.username = username;
              dispatch(
                editProfileSaga({ data: payload, success: handleSuccess })
              );
              togStandard();
            }}
            disabled={
              userData
                ? name !== userData?.name ||
                  email !== userData?.email ||
                  username !== userData?.username
                  ? false
                  : true
                : false
            }
          >
            Save changes
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MyProfile;
