export const APPLY_TO_BECOME_CREATOR_URL =
  "https://support.archivematter.io/creator-application";

export const GET_CURRENT_MATIC_VALUES =
  "https://min-api.cryptocompare.com/data/price?fsym=POL&tsyms=USD";
// "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,EUR,CNY,JPY,GBP";

export const SUPPORT_URL = "https://support.archivematter.io/";
export const PRESS_URL = "press@archivematter.io";
export const INFO_URL = "info@archivematter.io";
export const CAREERS_URL = "careers@archivematter.io";
export const REPORT_URL = "report@archivematter.io";
export const INSTAGRAM_URL = "https://www.instagram.com/archivematter.io/";
export const THREADS_URL = "https://www.threads.net/@archivematter.io";
export const MEDIUM_URL = "https://archivematter.medium.com/";
export const TWITTER_URL = "https://twitter.com/archivematter";
export const EXCLUSIVE_COMMERCIAL_LINK =
  "https://zwbr6oondqnuxjpmomjovvirixak4vaubrlphbxdpydgiiemaqoa.arweave.net/zYMfOc0cG0ul7HMS6tURRcCuVBQMVvOG434GZCCMBBw";
export const EXCLUSIVE_NONCOMMERCIAL_LINK =
  "https://njizqbhipwwmspss3uc46zadp6udb6w53zrys7wddrx2mfzohp6a.arweave.net/alGYBOh9rMk-Ut0Fz2QDf6gw-t3eY4l-wxxvphcuO_w";
export const NONEXCLUSIVE_NONCOMMERCIAL_LINK =
  "https://gur44wi6nvrb44ckwiedzhsnz5pkbkpxnlvbnfwlrf4theehdi4q.arweave.net/NSPOWR5tYh5wSrIIPJ5Nz16gqfdq6haWy4l5M5CHGjk";
export const NONEXCLUSIVE_COMMERCIAL_LINK =
  "https://2fewyusjebz3r4d424gchac6x74fdbto3qtajktsahr7ssms7geq.arweave.net/0UlsUkkgc7jwfNcMI4Bev_hRhm7cJgSqcgHj-UmS-Yk";

export const videoExtensions = ["mp4", "webm", "ogg"];
export const audioExtensions = ["mp3", "wav"];

export const licenceTypeValue = {
  0: "EXCLUSIVE | COMMERCIAL",
  1: "EXCLUSIVE | NON COMMERCIAL",
  2: "NON EXCLUSIVE | COMMERCIAL",
  3: "NON EXCLUSIVE | NON COMMERCIAL",
};

export const auctionDuration = 1 * 60 * 61;

export const listDelay = 2 * 60;
export const currentNetwork = 80002;
export const currentNetworkHex = "0x13882";

export const purchaseHistory = {
  _id: { $oid: "65327e36a6a3fd275d2dd11e" },
  rootNftId: { $oid: "65327dc7a6a3fd275d2dd102" },
  collectionId: { $oid: "651e5234f719d1e9bcacaeae" },
  nftIds: [{ $oid: "65327dc8a6a3fd275d2dd108" }],
  fractionAmount: 1,
  eventType: "Buy",
  fromUser: { $oid: "651c1b6b36914bbd022f7de1" },
  transactionId: { $oid: "65327e2ea6a3fd275d2dd10f" },
  saleDivision: { totalSale: [] },
  sellId: "3",
  createdAt: { $date: { $numberLong: "1697807926305" } },
  updatedAt: { $date: { $numberLong: "1697807926305" } },
  licenceType: 3,
  expiryTime: "2022-10-20T13:31:15.341+00:00",
};

export const todayMinDate = parseInt(
  +new Date(new Date().toJSON().slice(0, 10)) / 1000
);
