import * as actionLabels from "../../actionLabels";

// FOR LOGIN
export const submitFeedbackStart = (payload) => ({
  type: actionLabels.SUBMIT_FEEDBACK_START,
  payload,
});

export const submitFeedback = (payload, disconnectWallet) => ({
  type: actionLabels.SUBMIT_FEEDBACK_SAGA,
  payload,
  disconnectWallet,
});

export const submitFeedbackSuccess = (payload) => ({
  type: actionLabels.SUBMIT_FEEDBACK_SUCCESS,
  payload,
});

export const submitFeedbackFail = (payload) => ({
  type: actionLabels.SUBMIT_FEEDBACK_FAIL,
  payload,
});
