import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
// import errorImg from "../../assets/images/404-error.png";

const EmailVerificationFailed = () => {
  useEffect(() => {
    document.body.className = "page_background_color";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div>
        <Container>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 77px)",
              paddingBottom: "77px",
            }}
          >
            <Col md={12}>
              <div className="text-center">
                <h4
                  className="text-uppercase"
                  style={{ fontFamily: "'Montserrat'", color: "black" }}
                >
                  Email verification failed!
                </h4>
                <p className="" style={{ color: "black" }}>
                  Unfortunately, your email verification failed. Please try
                  resending the verification link to your registered email or
                  contact <u>support@blockthree.com</u>
                </p>
                <div className="mt-5">
                  <Link
                    className="btn btn-primary waves-effect"
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, rgb(176 218 17) 0px, rgb(96 181 35))",
                      borderRadius: "4px",
                      border: "none",
                    }}
                    to="/login"
                  >
                    Click here to continue
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EmailVerificationFailed;
