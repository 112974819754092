const getWeb3 = async (walletType) => {
  let web3;
  if (walletType === "WALLET_CONNECT_V2") {
  } else if (walletType === "COINBASE_WALLET") {
    web3 = window?.ethereum?.providers?.find(
      ({ isCoinbaseWallet, isCoinbaseBrowser }) =>
        isCoinbaseWallet || isCoinbaseBrowser
    );
  } else if (walletType === "INJECTED") {
    web3 = window?.ethereum?.providers?.find(({ isMetaMask }) => isMetaMask);
  }
  return web3;
};

export default getWeb3;
