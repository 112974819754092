import { ChainId } from "@uniswap/sdk-core";
import {
  WalletConnect,
  WalletConnectConstructorArgs,
} from "@web3-react/walletconnect-v2";
import { RPC_URLS } from "./networks";
// import { sendAnalyticsEvent } from "analytics";

const RPC_URLS_WITHOUT_FALLBACKS = Object.entries(RPC_URLS).reduce(
  (map, [chainId, urls]) => ({
    ...map,
    [chainId]: urls[0],
  }),
  {}
);
export class WalletConnectV2 extends WalletConnect {
  ANALYTICS_EVENT = "Wallet Connect QR Scan";
  constructor({
    actions,
    defaultChainId,
    qrcode = true,
    onError,
  }: Omit<WalletConnectConstructorArgs, "options"> & {
    defaultChainId: ChainId;
    qrcode?: boolean;
  }) {
    const darkmode = Boolean(window.matchMedia("(prefers-color-scheme: dark)"));
    super({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID as string,
        chains: [defaultChainId],
        optionalChains: [80002],
        showQrModal: qrcode,
        rpcMap: RPC_URLS_WITHOUT_FALLBACKS,
        optionalMethods: [
          "eth_signTypedData",
          "eth_signTypedData_v4",
          "eth_sign",
        ],
        qrModalOptions: {
          desktopWallets: undefined,
          enableExplorer: true,
          explorerExcludedWalletIds: undefined,
          explorerRecommendedWalletIds: undefined,
          mobileWallets: undefined,
          privacyPolicyUrl: undefined,
          termsOfServiceUrl: undefined,
          themeMode: darkmode ? "dark" : "light",
          themeVariables: {
            "--wcm-font-family": '"Inter custom", sans-serif',
            "--wcm-z-index": "1060",
          },
          walletImages: undefined,
        },
      },
      onError,
    });
  }

  activate(chainId?: ChainId) {
    // sendAnalyticsEvent(this.ANALYTICS_EVENT);
    return super.activate(chainId);
  }
}
