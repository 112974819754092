export * from "./auth/auth";
export * from "./modal/modal";
export * from "./layout/layout";
export * from "./admin/admin";
export * from "./user/user";
export * from "./profile/profile";
export * from "./dashboard/dashboard";
export * from "./help-center/helpCenter";
export * from "./ico/ico";
export * from "./referral/referral";

export const resetApp = (payload) => ({
  type: "RESET_APP",
  payload,
});

export const resetMessage = (payload) => ({
  type: "RESET_MESSAGE",
  payload,
});
export const resetMessageIco = (payload) => ({
  type: "RESET_MESSAGE_ICO",
  payload,
});

export const resetMessageFeedback = (payload) => ({
  type: "RESET_MESSAGE_FEEDBACK",
  payload,
});
