/* eslint-disable no-unused-vars */
import { Button } from "reactstrap";
import { put } from "redux-saga/effects";

import { errorHandler } from "../../../utils";

import {
  loginStart,
  loginFail,
  loginSuccess,
  signupStart,
  signupSuccess,
  signupFail,
  resendEmailStart,
  resendEmailSuccess,
  resendEmailFail,
  editProfileStart,
  editProfileSuccess,
  editProfileFail,
  fetchProfileInfoStart,
  fetchProfileInfoSuccess,
  fetchProfileInfoFail,
  checkUserInfoAvailabilityStart,
  checkUserInfoAvailabilitySuccess,
  checkUserInfoAvailabilityFail,
  resetApp,
  logoutStart,
  logoutSuccess,
  logoutFailed,
  updateTokenClaimStart,
  updateTokenClaimFail,
  updateTokenClaimSuccess,
  networkSwitchStart,
  networkSwitchSuccess,
} from "../../actions";

export function* loginSaga(action) {
  const { payload, success, navigate, logout } = action.payload;
  yield put(loginStart());
  yield errorHandler({
    endpoint: "/auth/sign-in",
    successHandler: yield function* (response) {

      yield put(loginSuccess(response?.data));
      if (response?.data?.token) {
        localStorage.setItem("token", response?.data?.token);
      }
      if(navigate) navigate("/dashboard");
      if (success) success();
    },
    failHandler:  yield function* (error) {
      yield put(loginFail(error));  
      if(logout) logout(error);  
    },
    failHandlerType: 'CUSTOM',
    apiType: "post",
    payload,
  });
}

export function* signupSaga({
  payload,
  handleModal,
  dispatch,
  disconnectWallet,
}) {
  yield put(signupStart());
  yield errorHandler({
    endpoint: "/auth/sign-up",
    successHandler: yield function* (response) {
      yield put(signupSuccess(response.data));
      disconnectWallet();
      localStorage.clear();
      // dispatch(resetApp());
      handleModal();
    },
    failHandler: signupFail,
    apiType: "post",
    payload,
  });
}
export function* checkUserInfoAvailability({ payload }) {
  yield put(checkUserInfoAvailabilityStart());
  yield errorHandler({
    endpoint: `/auth/availability?${payload}`,
    successHandler: yield function* (response) {
      yield put(checkUserInfoAvailabilitySuccess(response.data));
    },
    failHandler: checkUserInfoAvailabilityFail,
    apiType: "get",
  });
}

export function* updateTokenClaimSaga({ payload, disconnectWallet }) {
  yield put(updateTokenClaimStart());
  yield errorHandler({
    endpoint: "/me",
    successHandler: yield function* (response) {
      yield put(updateTokenClaimSuccess(response.data));
    },
    failHandler: updateTokenClaimFail,
    apiType: "patch",
    payload,
    token: localStorage.getItem("token"),
    disconnectWallet,
  });
}

export function* fetchProfileInfo() {
  yield put(fetchProfileInfoStart());
  yield errorHandler({
    endpoint: "/me",
    successHandler: yield function* (response) {
      const walletAddress = response?.data.items.walletAddress;
      if (walletAddress) {
        localStorage.setItem("walletAddress", walletAddress);
      } 
      console.log(walletAddress)
      yield put(fetchProfileInfoSuccess(response.data));
    },
    failHandler: fetchProfileInfoFail,
    apiType: "get",
    token: localStorage.getItem("token"),
    // disconnectWallet,
  });
}

export function* editProfileSaga(action) {
  const { data, success } = action.payload;
  yield put(editProfileStart());
  yield errorHandler({
    endpoint: "/me",
    successHandler: yield function* (response) {
      yield put(editProfileSuccess(response.data));
      success();
    },
    failHandler: editProfileFail,
    apiType: "patch",
    payload: data,
    token: localStorage.getItem("token"),
    // disconnectWallet,
  });
}

export function* resendEmailSaga({ payload }) {
  yield put(resendEmailStart());
  yield errorHandler({
    endpoint: "/auth/resend.verification-email",
    successHandler: yield function* (response) {
      yield put(resendEmailSuccess(response.data));
    },
    failHandler: resendEmailFail,
    apiType: "post",
    payload,
  });
}

export function* networkSwitchSaga({ payload }) {
  yield put(networkSwitchSuccess(payload));
}

export function* logoutSaga({ payload }) {
  const { modalHandler } = payload;
  yield put(logoutStart());
  try {
    // if (disconnectWallet) disconnectWallet();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("shouldEagerConnect", false);
    yield put(resetApp());
    yield put(logoutSuccess());
    modalHandler();
  } catch (e) {
    logoutFailed(e);
  }
}
