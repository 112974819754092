import Slider from "@mui/material/Slider";
import styled from "@emotion/styled";

const PrettoSlider = styled(Slider)({
  color: "#000000",
  height: 8,
  "& .MuiSlider-rail": {
    background: "#000000",
  },
  '& .MuiSlider-markLabel[data-index="0"]': {
    marginLeft: "11px",
  },
  '& .MuiSlider-markLabel[data-index="4"]': {
    marginLeft: "-11px",
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid #000000",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#7a7f77",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export default PrettoSlider;
